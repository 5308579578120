import React, { useState, useEffect } from "react";
import NavBar from "../Navbar/NavBar";
import logos_whatsapp from "../../Assests/logos_whatsapp.png";
import "./landing.css";
import { RiWallet3Fill } from "react-icons/ri";
import apple from "../../Assests/appleNew.svg";
import playsore from "../../Assests/playStore.svg";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import ArrowRightAltSharpIcon from "@mui/icons-material/ArrowRightAltSharp";
import NotificationsSharpIcon from "@mui/icons-material/NotificationsSharp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import useAnalytics from "../CustomHook/useAnalytics";

import Logo from "../../Assests/Frame.png";

const Thankyou = () => {
  const { trackEvent } = useAnalytics();
  const [toggleState, setToggleState] = useState("");
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const navigatetocendrol = () => {
    window.location.href = "https://cendrol.com";
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ overflow: "hidden" }}>
      {/* //navbar  */}
      <div
        style={{
          background:
            "linear-gradient(286.09deg, #FFF4D1 -4.1%, rgba(255, 244, 209, 0.47) 129.05%)",
        }}
      >
        <div>
          {/* <LandNav /> */}
          <div
            className="row justify-content-center"
            style={{ borderBottom: "1px solid rgba(14, 14, 14, 0.08)" }}
          >
            <div className="col-11 col-md-11">
              <nav className="navbar navbar-expand-lg navbar-light py-3">
                <div className="container-fluid">
                  <a href="https://www.cendrol.com">
                    <img
                      src={Logo}
                      className="cendrol_log"
                      alt=""
                      onClick={{ navigatetocendrol }}
                    />
                  </a>

                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo02"
                    aria-controls="navbarTogglerDemo02"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse "
                    id="navbarTogglerDemo02"
                  >
                    {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0 px-2"> */}
                    <ul className="navbar-nav mb-2 mb-lg-0 ">
                      <li
                        className="nav-item px-3 ull"
                        onClick={() => toggleTab(3)}
                      >
                        <a
                          className={
                            toggleState === 3
                              ? "nav-link nav-link-active ull"
                              : "nav-link"
                          }
                          href="#faq"
                        >
                          {/* FAQ's */}
                        </a>
                      </li>
                      <li
                        className="nav-item px-3"
                        onClick={() => toggleTab(4)}
                      >
                        <a
                          className={
                            toggleState === 4
                              ? "nav-link nav-link-active"
                              : "nav-link ull"
                          }
                          href="#features"
                        >
                          {/* Features */}
                        </a>
                      </li>
                      <li
                        className="nav-item align-self-center px-3"
                        style={{ listStyleImage: "unset" }}
                      >
                        <a
                          onClick={() =>
                            trackEvent("Clicked On Mail", {
                              button_label: "Clicked On Mail",
                            })
                          }
                          className="align-items-center cust_a cust_vline"
                          href="mailto:partner@cendrol.com"
                          title="partner@cendrol.com"
                          style={{ display: "flex" }}
                        >
                          {/* <DraftsIcon className="nav_mail_icons" /> */}
                          <svg
                            className="cust_svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.9395 3C18.2805 3 19.5705 3.53 20.5195 4.481C21.4695 5.43 22.0005 6.71 22.0005 8.05V15.95C22.0005 18.74 19.7305 21 16.9395 21H7.06049C4.26949 21 2.00049 18.74 2.00049 15.95V8.05C2.00049 5.26 4.25949 3 7.06049 3H16.9395ZM18.5305 9.54L18.6105 9.46C18.8495 9.17 18.8495 8.75 18.5995 8.46C18.4605 8.311 18.2695 8.22 18.0705 8.2C17.8605 8.189 17.6605 8.26 17.5095 8.4L13.0005 12C12.4205 12.481 11.5895 12.481 11.0005 12L6.50049 8.4C6.18949 8.17 5.75949 8.2 5.50049 8.47C5.23049 8.74 5.20049 9.17 5.42949 9.47L5.56049 9.6L10.1105 13.15C10.6705 13.59 11.3495 13.83 12.0605 13.83C12.7695 13.83 13.4605 13.59 14.0195 13.15L18.5305 9.54Z"
                              fill="#F7BA00"
                            />
                          </svg>
                          partner@cendrol.com
                        </a>
                      </li>

                      <li
                        className="nav-item align-self-center"
                        style={{ listStyleImage: "unset" }}
                      >
                        <a
                          className="align-items-center cust_a"
                          href="tel:9632888477"
                          title="+91 9632888477"
                          style={{ display: "flex" }}
                        >
                          <svg
                            className="cust_svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.615 4.80378C12.1985 4.72684 11.8159 4.99265 11.7366 5.39925C11.6573 5.80585 11.924 6.20282 12.3292 6.28239C13.5492 6.52023 14.4912 7.46458 14.7299 8.68874V8.68961C14.7979 9.042 15.1081 9.2982 15.4654 9.2982C15.5133 9.2982 15.5613 9.29383 15.6101 9.28508C16.0153 9.20376 16.2819 8.80766 16.2026 8.40019C15.8462 6.57182 14.4389 5.15966 12.615 4.80378"
                              fill="#FDC315"
                            />
                            <path
                              d="M12.5609 1.75694C12.3657 1.72896 12.1696 1.78667 12.0136 1.91083C11.8533 2.03675 11.7531 2.21862 11.7313 2.42236C11.6851 2.8342 11.9823 3.20669 12.3936 3.25304C15.23 3.56957 17.4347 5.77918 17.7536 8.62447C17.7963 9.00571 18.1161 9.29339 18.4978 9.29339C18.5266 9.29339 18.5545 9.29164 18.5832 9.28814C18.7828 9.26628 18.9605 9.16748 19.086 9.01008C19.2106 8.85269 19.2673 8.65683 19.2446 8.45659C18.8473 4.90653 16.0997 2.15129 12.5609 1.75694"
                              fill="#FDC315"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.65277 11.3508C13.1432 14.8403 13.9351 10.8034 16.1574 13.0242C18.3 15.1662 19.5314 15.5953 16.8168 18.3091C16.4768 18.5824 14.3164 21.87 6.72403 14.2797C-0.869294 6.6885 2.41639 4.52589 2.68972 4.18596C5.41088 1.46462 5.83263 2.70321 7.97518 4.84516C10.1976 7.06693 6.16233 7.86136 9.65277 11.3508Z"
                              fill="#0A0A0A"
                            />
                          </svg>
                          +91 9632888477
                        </a>
                      </li>
                    </ul>
                    <div
                      className="d-flex justify-content-around cust_div"
                      style={{ gap: "20px" }}
                    >
                      <div>
                        {/* <button
                            className="btn btn-lg land_nav_gett_app"
                            // onClick={handleUserPopUp}
                            onClick={() => {
                              DownlodAPPref.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            }}
                          >
                            Get the App
                          </button> */}
                      </div>
                      <div>
                        {/* <button
                      className="btn btn-lg land_nav_gett_app"
                      // onClick={handleUserPopUp}
                      // onClick={() => {
                      //   DownlodAPPref.current.scrollIntoView({
                      //     behavior: "smooth",
                      //   });
                      // }}
                    >
                      Get the App
                    </button> */}
                        <button
                          className="cust_btn"
                          onClick={() => {
                            window.open(
                              "https://play.google.com/store/search?q=cendrol%20partner&c=apps"
                            );
                          }}
                        >
                          <img src={playsore} alt="" />
                        </button>
                      </div>
                      <div>
                        {/* <button
                      className="btn btn-lg land_nav_gett_app"
                      // onClick={handleUserPopUp}
                      // onClick={() => {
                      //   DownlodAPPref.current.scrollIntoView({
                      //     behavior: "smooth",
                      //   });
                      // }}
                    >
                      Get the App
                    </button> */}
                        <button
                          className="cust_btn"
                          // onClick={handleUserPopUp}
                          // onClick={() => {
                          //   DownlodAPPref.current.scrollIntoView({
                          //     behavior: "smooth",
                          //   });
                          // }}
                        >
                          <img src={apple} alt="" />
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn btn-lg land_nav_signup"
                          // onClick={handleUserPopUp}
                          // data-bs-toggle="modal"
                          // data-bs-target=".bd-example-modal-user"
                          onClick={() => {
                            window.open(
                              "https://www.partnerlogin.cendrol.com/"
                            );
                          }}
                        >
                          Login / Signup
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="thankyou_main">
        <div>
          <div className="p-3">
            <div className="text-center thankyou_heading_page">
              Thank you for
              <br /> your interest in Cendrol.
            </div>
            <div className="d-flex justify-content-center">
              <div className="thanku_line"></div>
            </div>
            <p className="text-center thank_subheading_page">
              One of our Partner Representatives will be in contact with you
              shortly.
            </p>
            <div className="d-flex justify-content-center mb-3 mt-4">
              {/* <Link to="/" className="side_menu_dashboardbtn_unactive">
                <button className="keep_browsing">Keep Browsing</button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <Footer /> */}
        <div
          className="row justify-content-center"
          style={{ background: "#F9F9F9" }}
        >
          <div className="col-11 col-md-11">
            <div className="container-fluid">
              <div className="pb-5">
                <div className="land_footer_divider_line">
                  <div className="pb-5 row">
                    <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_start">
                      <div className="d-flex">
                        <div className="land_multiple_icons p-2">
                          <RiWallet3Fill className="land_colored_icons" />
                        </div>
                        <div className="land_footer_icon_with_text">
                          <span>
                            Get initial payment in <br /> 48 hours from the
                            project start date{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_center">
                      <div className="d-flex">
                        <div className="land_multiple_icons p-2">
                          <NotificationsSharpIcon className="land_colored_icons" />
                        </div>
                        <div className="land_footer_icon_with_text">
                          <span>
                            Don’t miss out <br /> Real-time lead updates!
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_end">
                      <div className="d-flex">
                        <div className="land_multiple_icons p-2">
                          <CurrencyRupeeIcon className="land_colored_icons" />
                        </div>
                        <div className="land_footer_icon_with_text">
                          <span>
                            Honest commission <br /> Flat ₹ 30- ₹ 50 per square
                            feet
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_start">
                    <div>
                      <div className="land_footer_text_heads">
                        <span>Start a Conversation</span>
                      </div>
                      <div className="pt-4 land_footer_mail">
                        <a
                          href="mailto:partner@cendrol.com"
                          title="partner@cendrol.com"
                          // className="land_footer_mail"
                        >
                          <span>partner@cendrol.com</span>
                        </a>
                        {/* <span>sales@cendrol.com</span> */}
                        <span>
                          <ArrowRightAltSharpIcon className="land_footer_btn_icon_get" />
                        </span>
                      </div>
                      <br />
                      {/* <Link
                          to={`/privacy`}
                          target="_blank"
                          className="mt-3 PrivacyPolicy "
                        >
                          Privacy Policy
                        </Link> */}
                      <a
                        className="mt-3 PrivacyPolicy "
                        href="https://www.cendrol.com/privacy/"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>

                  <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_center">
                    <div>
                      <div className="land_footer_text_heads">
                        <span>General</span>
                      </div>
                      <div className="pt-4 land_footer_number">
                        <a
                          onClick={() =>
                            trackEvent("Call Button Clicked", {
                              button_label: "Call Button Clicked",
                            })
                          }
                          href="tel:9632888477"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="+91 9632888477"
                          className="land_footer_number land-foot"
                        >
                          +91 9632888477
                        </a>
                      </div>
                      <div className="pt-4 land_footer_icons">
                        <a
                          onClick={() =>
                            trackEvent("twitter", {
                              button_label: "twitter",
                            })
                          }
                          href="https://twitter.com/CendrolPartners"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="twitter"
                          className="land_footer_icons"
                        >
                          <TwitterIcon />
                        </a>

                        <a
                          onClick={() =>
                            trackEvent("linkedin", {
                              button_label: "linkedin",
                            })
                          }
                          href="https://www.linkedin.com/company/cendrol-partners"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="linkedin"
                          className="land_footer_icons"
                        >
                          <LinkedInIcon />
                        </a>

                        <a
                          onClick={() =>
                            trackEvent("Instagram", {
                              button_label: "Instagram",
                            })
                          }
                          href="https://instagram.com/cendrolpartner?igshid=YmMyMTA2M2Y="
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Instagram"
                          className="land_footer_icons"
                        >
                          <InstagramIcon />
                        </a>

                        <a
                          onClick={() =>
                            trackEvent("facebook", {
                              button_label: "facebook",
                            })
                          }
                          href="https://m.facebook.com/cendrolpartners/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="facebook"
                          className="land_footer_icons"
                        >
                          <FacebookSharpIcon />
                        </a>

                        <a
                          onClick={() =>
                            trackEvent("Youtube", {
                              button_label: "Youtube",
                            })
                          }
                          href="https://www.youtube.com/@cendrolpartners"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Youtube"
                          className="land_footer_icons"
                        >
                          <YouTubeIcon />
                        </a>
                        <a
                          onClick={() =>
                            trackEvent("Pinterest", {
                              button_label: "Pinterest",
                            })
                          }
                          href="https://pin.it/5cJyHsR"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Pinterest"
                          className="land_footer_icons"
                        >
                          <PinterestIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_end">
                    <div>
                      <div className="land_footer_text_heads">
                        <span>Office</span>
                      </div>
                      <div className="pt-3 land_footer_number">
                        <span>
                          Cendrol Construction Contracts PVT LTD <br />
                        </span>
                        <span>
                          3rd floor, Cendrol, 299, 288, Outer Ring Rd, J. P.
                          Nagar, Bengaluru, Karnataka 560078
                        </span>
                      </div>

                      {/* <div className="pt-4 land_footer_privacy_policy">
                          <span>Privacy Policy | Terms & Conditions</span>
                        </div> */}
                    </div>
                  </div>

                  {/* <div className="pt-5 col-12 col-sm-12 col-md-3 display_footer_end">
                      <div>
                        <span onClick={() => toggleTab1(1)}>
                          <a
                            className={
                              toggleState1 === 1
                                ? "land_footer_nav_items land_footer_nav_items_active"
                                : "land_footer_nav_items"
                            }
                            href="#home"
                          >
                            Home
                          </a>
                        </span>
                        <br />
                        <span onClick={() => toggleTab1(2)}>
                          <a
                            className={
                              toggleState1 === 2
                                ? "land_footer_nav_items land_footer_nav_items_active"
                                : "land_footer_nav_items"
                            }
                            href="#benefits"
                          >
                            Benefits
                          </a>
                        </span>
                        <br />
                        <span onClick={() => toggleTab1(3)}>
                          <a
                            className={
                              toggleState1 === 3
                                ? "land_footer_nav_items land_footer_nav_items_active"
                                : "land_footer_nav_items"
                            }
                            href="#faq"
                          >
                            FAQ's
                          </a>
                        </span>
                        <br />
                        <span onClick={() => toggleTab1(4)}>
                          <a
                            className={
                              toggleState1 === 4
                                ? "land_footer_nav_items land_footer_nav_items_active"
                                : "land_footer_nav_items"
                            }
                            href="#features"
                          >
                            Features
                          </a>
                        </span>
                        <br />
                        <span
                          className="land_footer_nav_items"
                          style={{ cursor: "pointer" }}
                          onClick={videoplay}
                        >
                         How It Works
                        </span>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div_overflow footer">
        <div className="boxCopyright">
          <div className="container">
            <p className="mb-0">
              © {new Date().getFullYear()} www.cendrol.com All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
