import React, { useEffect } from "react";
import Land_footer from "../Footer/Land_footer";
import "./Privacy.css";
import Header from "../reUse/Header";

const TermsServices = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="custom-heading">Terms Of Service</h1>
            <div class="mt-4">
              <h2 class="mt-4 ">1. Acceptance of Terms</h2>
              <p class="custom-paragraph">
                These Terms of Service ("Agreement") constitute a legal
                agreement between you ("Partner," "you," or "your") and Cendrol
                Construction Pvt Ltd, a company limited by shares organized
                under the laws of Bengaluru ("Company," "we," "us," or "our").
                By using the Partner Mobile App ("App"), you agree to abide by
                and be bound by the terms and conditions set forth in this
                Agreement.
              </p>

              <h2 class="mt-4 ">2. Use of the App</h2>
              <p class="custom-paragraph">
                You are granted a non-exclusive, non-transferable, revocable
                license to access and use the App for the purpose of submitting
                leads for potential construction projects. You agree not to use
                the App for any unlawful or unauthorized purpose. You are solely
                responsible for any content you submit through the App.
              </p>

              <h2 class="mt-4 ">3. Lead Submission</h2>
              <p class="custom-paragraph">
                You may use the App to submit leads for construction projects
                seeking services. You warrant that all information submitted is
                accurate and legitimate. Any false or misleading information may
                result in the termination of your account.
              </p>

              <h2 class="mt-4 ">4. Commission and Payments</h2>
              <p class="custom-paragraph">
                When a lead submitted through the App results in a successful
                project closure, you may be eligible for a commission fee as per
                the commission fee structure communicated by the Company.
                Commission payouts will be processed based on predetermined
                stages of project completion. The Company reserves the right to
                withhold or adjust payouts in case of disputes or discrepancies.
              </p>

              <h2 class="mt-4 ">5. Intellectual Property</h2>
              <p class="custom-paragraph">
                The App, including its design, features, logos, and trademarks,
                are the intellectual property of the Company and are protected
                by applicable intellectual property laws. You shall not
                reproduce, distribute, or modify any part of the App without
                explicit written consent from the Company.
              </p>
              <h2 class="mt-4 ">6. Privacy </h2>
              <p class="custom-paragraph">
                Your use of the App is subject to our Privacy Policy, which can
                be found on our website. By using the App, you consent to the
                collection, use, and sharing of your information as described in
                the Privacy Policy.
              </p>

              <h2 className="mt-4 ">7. Termination</h2>
              <p className="custom-paragraph">
                The Company reserves the right to suspend or terminate your
                access to the App at any time, with or without cause.
                Termination may result from violation of these terms, misuse of
                the App, or any other reason deemed appropriate by the Company.
              </p>

              <h2 className="mt-4 ">8. Modifications</h2>
              <p className="custom-paragraph">
                The Company may update, modify, or discontinue the App and its
                features at any time without prior notice. You agree that the
                Company shall not be liable for any losses arising from such
                modifications.
              </p>
              <h2 className="mt-4 ">9. Disclaimers </h2>
              <p className="custom-paragraph">
                The App is provided "as is" and without warranties of any kind,
                whether express or implied. The Company disclaims any liability
                for the accuracy, completeness, or reliability of the
                information submitted through the App.{" "}
              </p>

              <h2 className="mt-4 ">10. Governing Law</h2>
              <p className="custom-paragraph">
              This Agreement shall be governed by and construed in accordance with the laws of 175 & 176, Bannerghatta Main Rd, Dollars Colony, Phase 4, J. P. Nagar, Bengaluru, Karnataka 560076. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in 175 & 176, Bannerghatta Main Rd, Dollars Colony, Phase 4, J. P. Nagar, Bengaluru, Karnataka 560076.
              </p>

              <h2 className="mt-4 "> 11. Contact </h2>
              <p className="custom-paragraph">
                If you have any questions or concerns regarding this Agreement
                or the App, you may contact us at partner@cendrol.com..
              </p>
              <p className="custom-paragraph">
                {" "}
                By using the Partner Mobile App, you acknowledge that you have
                read, understood, and agreed to the terms and conditions
                outlined in this Agreement.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Land_footer />
    </div>
  );
};

export default TermsServices;
