import React from "react";
import Graph from "../../Assests/earning_grah.png";
import Graph2 from "../../Assests/graph2.png";
import Graph3 from "../../Assests/graph3.png";
import Total_Earning from "../../Assests/Features/Total Earning.svg";
import Lead_status from "../../Assests/Features/Lead Status.svg";
import pay_Earning from "../../Assests/Features/Payment Status.svg";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Total_Earningm from "../../Assests/Features/Total Earning(Mobile).svg";
import Lead_statusm from "../../Assests/Features/Lead Status(Mobile).svg";
import pay_Earningm from "../../Assests/Features/Payment Status(Mobile).svg";
const Land_Earning = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-11 col-md-11">
        <div className="container-fluid">
          <div className="pb-5 row desk_disp">
            <div className="pt-5 col-12 col-sm-12 col-md-12">
              <div>
                {/* <img src={Graph} className="land_earning_graph" /> */}
                <div
                  id="carousel-feedback"
                  className="carousel slide carousel-feedback"
                  data-bs-ride="carousel"
                  data-bs-interval="5000"
                >
                  <div className="pb-5 carousel-inner">
                    <div className="carousel-item active">
                      <img src={Total_Earning} className="land_earning_graph" />
                    </div>
                    <div className="carousel-item">
                      <img src={Lead_status} className="land_earning_graph" />
                    </div>
                    <div className="carousel-item">
                      <img src={pay_Earning} className="land_earning_graph" />
                    </div>
                  </div>
                  <button
                    className="land_feedback_arrow_prev"
                    type="button"
                    data-bs-target="#carousel-feedback"
                    data-bs-slide="prev"
                  >
                    <BsArrowLeft className="land_arrow_icon" />
                  </button>
                  <button
                    className="land_feedback_arrow_next"
                    type="button"
                    data-bs-target="#carousel-feedback"
                    data-bs-slide="next"
                  >
                    <BsArrowRight className="land_arrow_icon" />
                  </button>
                </div>
              </div>
            </div>

            {/* <div class="carousel-indicators list">
                <div class="list__item">
                  <div class="circle">
                    <svg viewBox="0 0 52 52" class="circle-back">
                      <circle cx="26" cy="26" r="25" />
                    </svg>
                    <svg viewBox="0 0 52 52" class="circle-front">
                      <circle cx="26" cy="26" r="25" />
                    </svg>
                    <span
                      data-bs-target="#slider"
                      data-bs-slide-to="0"
                      class="active"
                    >
                      1
                    </span>
                  </div>
                </div>
                <div class="list__item">
                  <div class="circle">
                    <svg viewBox="0 0 52 52" class="circle-back">
                      <circle cx="26" cy="26" r="25" />
                    </svg>
                    <svg viewBox="0 0 52 52" class="circle-front">
                      <circle cx="26" cy="26" r="25" />
                    </svg>
                    <span>2</span>
                  </div>
                </div>
                <div class="list__item">
                  <div class="circle">
                    <svg viewBox="0 0 52 52" class="circle-back">
                      <circle cx="26" cy="26" r="25" />
                    </svg>
                    <svg viewBox="0 0 52 52" class="circle-front">
                      <circle cx="26" cy="26" r="25" />
                    </svg>
                    <span>3</span>
                  </div>
                </div>
              </div> */}
          </div>
          <div className="pb-5 row mobile_disp">
            <div className="pt-5 col-12 col-sm-12 col-md-12">
              <div>
                {/* <img src={Graph} className="land_earning_graph" /> */}
                <div
                  id="slider1"
                  className="carousel slide slider1"
                  data-bs-ride="carousel"
                  data-bs-interval="5000"
                >
                  <div className="pb-5 carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src={Total_Earningm}
                        className="land_earning_graph"
                      />
                    </div>
                    <div className="carousel-item">
                      <img src={Lead_statusm} className="land_earning_graph" />
                    </div>
                    <div className="carousel-item">
                      <img src={pay_Earningm} className="land_earning_graph" />
                    </div>
                  </div>
                  <div className="mob_icons">
                    <button
                      className="card_carsoul-prev"
                      type="button"
                      data-bs-target="#slider1"
                      data-bs-slide="prev"
                    >
                      <BsArrowLeft className="land_arrow_icon" />
                    </button>
                    <button
                      className="card_carsoul-prev"
                      type="button"
                      data-bs-target="#slider1"
                      data-bs-slide="next"
                    >
                      <BsArrowRight className="land_arrow_icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pt-5 col-12 col-sm-12 col-md-6">
              <div
                id="slider"
                className="carousel slide"
                data-bs-ride="carousel"
                data-bs-interval="5000"
              >
                <div className="pb-5 carousel-inner">
                  <div className="carousel-item active">
                    <div className="land_earning_head_text">
                      <span> Track your earnings - Anytime, anywhere!</span>
                    </div>
                    <div className="pt-4 land_earning_sub_text">
                      <span>
                        Know your total earnings at any point in time on our
                        app. Track how your leads are converting on the go.{" "}
                        <br />
                        <br />
                        Get real-time data with customized monthly and daily
                        graphs.
                      </span>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="land_earning_head_text">
                      <span>
                        Stay updated on the
                        <br /> lead status
                      </span>
                    </div>
                    <div className="pt-4 land_earning_sub_text">
                      <span>
                        Monitor your lead status and track their progress from
                        the booking to the meeting stage, on our app.
                      </span>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="land_earning_head_text">
                      <span>
                        View stage-wise <br />
                        payment status
                      </span>
                    </div>
                    <div className="pt-4 land_earning_sub_text">
                      <span>
                        Track stage-wise payments for every lead and know the
                        payments that are due to you, from the start of the
                        construction till the very end.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="pt-4">
                  <ol
                    className="d-flex justify-content-between carousel-indicators-numbers"
                    style={{ width: "50%" }}
                  >
                    <li
                      data-bs-target="#slider"
                      data-bs-slide-to="0"
                      className="active"
                    >
                      1
                    </li>
                    <li data-bs-target="#slider" data-bs-slide-to="1">
                      2
                    </li>
                    <li data-bs-target="#slider" data-bs-slide-to="2">
                      3
                    </li>
                  </ol>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land_Earning;
