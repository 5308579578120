import React, { useEffect, useState } from "react";
// import video from "../../Assests/SFX.MP4";
import video from "../../Assests/SFX_final.mp4";
import Cendrol from "../../Assests/cendrol.png";
import vidthumbnail from "../../Assests/thumbnail.png";

const Land_video = ({ autoplay }) => {
  //   const vidRef = useRef(null);

  //   useEffect(() => {
  //     vidRef.current.play();
  //   });

  const playVideo = (e) => {
    // var resp = e.target.play();
    // if (resp !== undefined) {
    //   resp
    //     .then((_) => {
    //       // autoplay starts!
    //       e.target.play();
    //     })
    //     .catch((error) => {
    //       //show error
    //     });
    // }
    e.target.play();
  };

  const pauseVideo = (e) => {
    e.target.pause();
  };

  return (
    <div className="row justify-content-center">
      <div className="col-11 col-md-11">
        <div className="">
          <div className="land_video_parent">
            <video
              id="myVideo"
              className="land_video"
              src={video}
              // ref={vidRef}
              //   controls
              muted
              //   onClick={playVideo}
              //   onPause={pauseVideo}
              autoPlay={autoplay}
              controls="hidden"
              controlsList="nodownload"
              //   autoPlay
              poster={vidthumbnail}
              onMouseOver={playVideo}
              onMouseOut={pauseVideo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land_video;
