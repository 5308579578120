import React from "react";
import NavBar from "../Navbar/NavBar";
import Land_footer from "../Footer/Land_footer";

const DashBoard = () => {
  return (
    <div>
      <div className="div_overflow">
        <NavBar />
      </div>
      <div>
        <Land_footer />
      </div>
    </div>
  );
};

export default DashBoard;
