import React, { useState, useEffect } from "react";
import CalImg from "../../Assests/cal_img.png";

const Land_Calculator = () => {
  const [value, setValue] = useState(10);
  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    let term = queryParams.get("earning");

    if (term) {
      window.location = "#earning";
    }
  }, []);
  return (
    <div className="row justify-content-center" id="earning">
      <div className="col-11 col-md-11">
        <div className="container-fluid">
          <div className="row pb-5">
            <div className="pt-5 col-12 col-sm-12 col-md-7 align-self-center">
              <div className="cal_head_txt">
                <span>
                  How much can <br />I earn?
                </span>
              </div>

              <div className="pt-3">
                <div className="row">
                  <div className="p-3 col-12 col-sm-12 col-md-8 cal_val_display">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 val_border">
                        <div>
                          <span className="val_head_txt">Monthly Earnings</span>
                          <div className="pt-2">
                            <span className="cal_val">
                              &#8377; {value * 98000}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 justify-content-start">
                        <div>
                          <span className="val_head_txt">Annual Earnings</span>
                          <div className="pt-2">
                            <span className="cal_val">
                              &#8377; {value * 98000 * 12}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4"></div>
                </div>

                <div className="pt-4 cal_width">
                  <div className="d-flex justify-content-between">
                    <div className="cal_project_number">
                      <span>Number of Leads Referred</span>
                    </div>
                    <div className="cal_calculated_value">
                      <span>{value}</span>
                    </div>
                  </div>

                  <div className="slider-parent">
                    <div>
                      <input
                        style={{
                          width: "100%",
                          accentColor: "#221E1F",
                          cursor: "pointer",
                        }}
                        type="range"
                        min="0"
                        step="1"
                        max="100"
                        value={value}
                        onChange={({ target: { value: radius } }) => {
                          setValue(parseInt(radius));
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="p-3 d-flex justify-content-start cal_val_display">
                  <div>
                    <span className="val_head_txt">Monthly Earning</span>
                    <div className="pt-2">
                      <span className="land_calculated_value">
                        &#8377; {value * 250} Lakhs
                      </span>
                    </div>
                  </div>
                  <div>
                    <span className="val_head_txt">Annual Earning</span>
                    <div className="pt-2">
                      <span className="land_calculated_value">
                        &#8377; {value * 250 * 12} Lakhs
                      </span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="pt-5 col-12 col-sm-12 col-md-5">
              <img src={CalImg} className="cal_img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land_Calculator;
