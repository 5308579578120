import React, { useEffect } from "react";
import "./Privacy.css";
import Land_footer from "../Footer/Land_footer";
import Header from "../reUse/Header";

const TermsConditon = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="custom-heading">Terms & Conditions</h1>
            <div class="mt-4">
              <h2 class="">
                Terms and Conditions for Partner Mobile App for Construction
                Companies in India
              </h2>
              <p class="custom-paragraph">
                These Terms and Conditions ("Agreement") are entered into by and
                between Cendrol Construction Pvt Ltd, a company limited by
                shares organized under the laws of Bengaluru, hereinafter
                referred to as "Company," and the registered users of the
                Company's mobile application, hereinafter referred to as
                "Partner."
              </p>

              <h2 class="mt-4 ">1. Acceptance of Terms:</h2>
              <p class="custom-paragraph">
                By registering and using the Partner Mobile App ("App"), the
                Partner agrees to abide by and be bound by the terms and
                conditions outlined in this Agreement
              </p>

              <h2 class="mt-4 ">2. Registration:</h2>
              <p class="custom-paragraph">
                Partner must provide accurate and complete information during
                the registration process. The Partner is responsible for
                maintaining the confidentiality of their login credentials and
                for all activities that occur under their account.
              </p>

              <h2 class="mt-4 ">3. Lead Submission:</h2>
              <p class="custom-paragraph">
                The Partner can use the App to submit leads for potential
                construction projects. These leads must be accurate and
                legitimate. Any false or misleading information provided may
                result in termination of the Partner's account.
              </p>

              <h2 class="mt-4 ">4. Conversion and Commission:</h2>
              <p class="custom-paragraph">
                When a lead submitted by the Partner through the App results in
                a successful closure of a construction project, the Partner
                becomes eligible for a commission fee based on predetermined
                stages of the project's completion. The specific commission fee
                structure will be communicated separately and can be revised by
                the Company at its discretion.
              </p>

              <h2 class="mt-4 ">5. Commission Payout:</h2>
              <p class="custom-paragraph">
                Commission earned by the Partner will be disbursed as per the
                commission fee structure mentioned in the Agreement. Payouts
                will be processed after verification of the closure and
                completion of the specific project stages. The Company reserves
                the right to withhold or adjust commission payouts in case of
                project disputes or discrepancies.
              </p>
              <h2 class="mt-4 ">6. Communication and Notifications: </h2>
              <p class="custom-paragraph">
                The Partner agrees to receive communication and notifications
                from the Company related to leads, closures, commission, and any
                other relevant updates. These communications may be delivered
                through the App, email, or other means.
              </p>

              <h2 className="mt-4 ">7. Compliance with Laws:</h2>
              <p className="custom-paragraph">
                Partner agrees to comply with all applicable laws, regulations,
                and industry standards while using the App and submitting leads.
                The Partner is solely responsible for obtaining any necessary
                permissions, licenses, or approvals required for their business
                activities.
              </p>

              <h2 className="mt-4 ">8. Intellectual Property:</h2>
              <p className="custom-paragraph">
                The Partner acknowledges that all intellectual property rights,
                including but not limited to trademarks, logos, and the App
                itself, belong to the Company. The Partner shall not use,
                reproduce, or modify any of the Company's intellectual property
                without explicit written permission.
              </p>
              <h2 className="mt-4 ">9. Termination: </h2>
              <p className="custom-paragraph">
                {" "}
                The Company reserves the right to suspend or terminate the
                Partner's access to the App at any time, with or without cause.
                Termination may result from violation of these terms, misuse of
                the App, or any other reason deemed appropriate by the Company.
              </p>

              <h2 className="mt-4 ">10. Modifications:</h2>
              <p className="custom-paragraph">
                The Company reserves the right to modify, update, or discontinue
                the App and its features at any time without prior notice. The
                Partner agrees that the Company shall not be liable for any
                losses arising from such modifications.
              </p>

              <h2 className="mt-4 ">11. Governing Law and Jurisdiction: </h2>
              <p className="custom-paragraph">
                This Agreement shall be governed by and construed in accordance
                with the laws of 175 & 176, Bannerghatta Main Rd, Dollars Colony, Phase 4, J. P. Nagar, Bengaluru, Karnataka 560076. Any
                disputes arising out of or in connection with this Agreement
                shall be subject to the exclusive jurisdiction of the courts in
                175 & 176, Bannerghatta Main Rd, Dollars Colony, Phase 4, J. P. Nagar, Bengaluru, Karnataka 560076.
              </p>
              <p className="custom-paragraph">
                {" "}
                By using the Partner Mobile App, the Partner acknowledges that
                they have read, understood, and agreed to the terms and
                conditions outlined in this Agreement.
              </p>
              <p className="custom-paragraph">
                This Agreement constitutes the entire understanding between the
                parties and supersedes all prior agreements, whether oral or
                writte
              </p>
            </div>
          </div>
        </div>
      </div>
      <Land_footer />
    </div>
  );
};

export default TermsConditon;
