import React, { useEffect } from "react";
import NavBar from "../Navbar/NavBar";
import "./Privacy.css";
import Land_footer from "../Footer/Land_footer";
import Header from "../reUse/Header";
import LogoFrame from "../../Assests/Frames.png";

export default function Privacy() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div style={{ overflowX: "hidden" }}>
      {/* test navbar  */}

      <Header />

      <div class="container" style={{ overflowX: "hidden" }}>
        <div class="row">
          <div class="col-md-12">
            <h1 class="custom-heading">Privacy Policy</h1>
            <div class="mt-4">
              <h2 class="mt-4 ">1. Introduction </h2>
              <p class="custom-paragraph">
                This Privacy Policy ("Policy") outlines how Cendrol Construction Pvt Ltd, a company limited by shares organized under the laws of Bengaluru ("Company," "we," "us," or "our"), collects, uses, and protects the information you provide when using the Partner Mobile App ("App"). By using the App, you consent to the practices described in this Policy.
              </p>

              <h2 class="mt-4 ">2. Information We Collect</h2>
              <p class="custom-paragraph" style={{ marginTop: "30px" }}>
                We may collect the following types of information when you use
                the App:
              </p>
              <p className="custom-paragraph" style={{ marginTop: "30px" }}>- Personal Information: This includes your name, phone number, and other details you provide during the registration process.</p>
              <p className="custom-paragraph" style={{ marginTop: "30px" }}>- Lead Information: Information related to construction leads you submit through the App, such as project details and requirements.</p>
              <p className="custom-paragraph" style={{ marginTop: "30px" }}>- Ownership of Lead Details: Once a lead is added in the app, the lead and all associated details like phone number, name of the leads are owned by the Company and may be used by the Company in any necessary means.</p>
              <p className="custom-paragraph" style={{ marginTop: "30px" }}>- Usage Information: We may collect information about how you use the App, including interactions, clicks, and navigation.</p>

              <h2 class="mt-4 ">3. How We Use Your Information</h2>
              <p class="custom-paragraph" >
                We use the collected information for the following purposes:
              </p>
              <p className="custom-paragraph mt-10" style={{ marginTop: "30px" }}>- To facilitate lead submission and communication between you and
                potential clients.</p>
              <p className="custom-paragraph">- To process commission payouts as per the
                terms of the App.</p>
              <p className="custom-paragraph">- To improve the functionality and user
                experience of the App.</p>
              <p className="custom-paragraph">- To send you important updates,
                notifications, and promotional materials.</p>
              <h2 class="mt-4 ">4. Information Sharing</h2>
              <p class="custom-paragraph">
                {" "}
                We may share your information with:
              </p>
              <p class="custom-paragraph" style={{ marginTop: "30px" }}>- Potential clients who are
                interested in our construction services based on the leads you
                submit.</p>
              <p class="custom-paragraph">- Third-party service providers who assist us in
                operating the App and providing related services.</p>
              <p class="custom-paragraph"> - Legal
                authorities or as required by law to comply with legal
                processes.{" "}</p>
              <p class="custom-paragraph" style={{ marginTop: "32px" }}>Third-Party Authentication Services</p>
              <p class="custom-paragraph" style={{ marginTop: "42px", marginBottom: "42px" }}>For authentication purposes, such as OTP verification, PAN verification, and bank details verification, we may use third-party services. These services may collect and process your personal information in accordance with their own privacy policies.</p>


              <h2 class="mt-4 ">5. Data Security</h2>
              <p class="custom-paragraph">
                We employ industry-standard security measures to protect your
                information from unauthorized access, disclosure, alteration, or
                destruction.{" "}
              </p>
              <h2 class="mt-4">6. Deletion and Deactivation of Your Account</h2>
              <p class="custom-paragraph" style={{marginTop:"32px"}}>- Request for Deactivation or Deletion: You have the option to request the deactivation or deletion of your account, either within the App or by visiting <a href="https://www.deleteaccount.cendrol.com" target="_blank" className="delteanchor">deleteaccount.cendrol.com</a> if you do not have the App installed.</p>
              <p class="custom-paragraph">- Immediate Deactivation: Deactivation of your account will take effect immediately upon request.</p>
              <p class="custom-paragraph">- Reactivation: Should you wish to reactivate your account after deactivation, a simple login is sufficient to reactivate your account.</p>
              <p class="custom-paragraph">- Deletion Process: If you choose to delete your account, please note that the deletion process may take up to 45 days to complete.</p>
              <p class="custom-paragraph" style={{marginBottom:"32px"}}>- Cancellation of Deletion Request: If you initiated a delete request and decide to revert back you may log in if your account has not been deleted yet. For further assistance, please contact us.</p>
              <h2 class="mt-4">7. Your Choices </h2>
              <p class="custom-paragraph" >
                You can update your personal information and communication
                preferences by accessing your account settings within the App.
                You can also unsubscribe from promotional emails using the
                provided opt-out mechanism.
              </p>

              <h2 className="mt-4 ">8. Third-Party Links </h2>
              <p className="custom-paragraph">
                The App may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such third parties. Your interactions with third-party websites are subject to their respective privacy policies.
              </p>

              <h2 className="mt-4 ">9. Children's Privacy</h2>
              <p className="custom-paragraph">
                The App is not intended for use by individuals under the
                age of 18. We do not knowingly collect or solicit personal
                information from anyone under the age of 18.{" "}
              </p>
              <h2 className="mt-4 ">10. Changes to this Policy</h2>
              <p className="custom-paragraph">
                We may update this Policy from time to time to reflect changes
                in our practices or legal requirements. Any modifications will
                be effective when posted on the App.{" "}
              </p>

              <h2 className="mt-4 ">11 Contact Us</h2>
              <p className="custom-paragraph">
                If you have any questions or concerns about this Policy or our
                privacy practices, you can contact us at partner@cendrol.com.
              </p>
              <p className="custom-paragraph">
                By using the Partner Mobile App, you acknowledge that you have
                read, understood, and agreed to the practices described in this
                Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Land_footer />
    </div>
  );
}
