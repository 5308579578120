import React, { useState, useRef, useEffect } from "react";
import "./landing.css";
import ThankYou from "../../Assests/thankyou.png";
import ArrowRightAltSharpIcon from "@mui/icons-material/ArrowRightAltSharp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import Hero from "../../Assests/hero.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";
import LandEarning from "./Land_Earning";
import { CgMouse } from "react-icons/cg";
import apple from "../../Assests/appleNew.svg";
import playsore from "../../Assests/playStore.svg";
import appleicon from "../../Assests/apple_ind.svg";
import LandCalculator from "./Land_Calculator";
import LandCardCarsoul from "./Land_Card_Carsoul";
import LandFAQ from "./Land_FAQ";
import logos_whatsapp from "../../Assests/logos_whatsapp.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Mobile from "../../Assests/refer_mobile_pic.png";
import LandVideo from "./Land_video";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { RiWallet3Fill } from "react-icons/ri";
import fetaure_1 from "../../Assests/feature_3.png";
import fetaure_2 from "../../Assests/feature_2.png";
import AddIcon from "@mui/icons-material/Add";
import thumb from "../../Assests/thumb.png";
import NotificationsSharpIcon from "@mui/icons-material/NotificationsSharp";
import { shareTextToWhatsApp } from "share-text-to-whatsapp";
import feat1 from "../../Assests/Per sqft.png";
import feat2 from "../../Assests/message-time.png";
import feat3 from "../../Assests/money-send.png";
import feat4 from "../../Assests/bx_bx-rupee.png";
import {
  FaThumbsUp,
  FaMedal,
  FaRupeeSign,
  FaHandHolding,
  FaHandshake,
} from "react-icons/fa";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import playicon from "../../Assests/playsote_ind.svg";
import Logo from "../../Assests/Frame.png";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import App from "../../Assests/mobile_app.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/reUse/Loader";
import playstore_1 from "../../Assests/new_three.svg";
import applestore_1 from "../../Assests/new_one.svg";
import useAnalytics from "../CustomHook/useAnalytics";

toast.configure();

const Landing = () => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const SERVER_URL = process.env.REACT_APP_BASE_URL;
  const ref = useRef(null);
  const videoref = useRef(null);
  const DownlodAPPref = useRef(null);
  const [videoactive, setvideoactive] = useState(false);
  const [loader, setLoader] = useState(false);
  const [disable, setDisable] = useState(true);
  const [auto, setAuto] = useState(false);

  // useEffect(() => {
  //   ref.current.scrollIntoView();
  // }, []);

  const sharewhatsapp = () => {
    const message = "https://www.partner.cendrol.com";
    shareTextToWhatsApp(message);
  };
  const otpfocus = useRef();
  const [toggleState, setToggleState] = useState("");
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [toggleState1, setToggleState1] = useState("");
  const toggleTab1 = (index) => {
    setToggleState1(index);
  };

  const [number, setnumber] = useState([]);
  const [otp, setotp] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is Required";
    } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
      error = "Enter a Valid Name It should contain only Aplhabets";
    } else if (value.length > 30) {
      error = "Name Should not more than 30";
    } else if (value.length < 3) {
      error = "Name Should not less than 3 Characters";
    }
    return error;
  }

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validateMobileNumber(value) {
    let error;
    if (!value) {
      error = "Mobile Number is Required";
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(value)) {
      error = "Invalid Mobile number";
    }
    return error;
  }

  // Handle Change for Number & To send Otp
  const handleChangeNumber = (e) => {
    setnumber(e.target.value);
    let mobile = e.target.value;
    // if (mobile.length == 0 && e.which == 48) {
    //   return false;
    // }
    // var regex = /^[0-9]$/;
    // if (mobile.match(regex)) {
    //   if (mobile.length === 10) {
    //     alert("succcesed");
    //   }
    // } else {
    //   alert("err");
    // }
    var regex = /^[0-9]{10}$/;
    if (mobile.length === 10) {
      if (mobile.match(regex)) {
        const data = { mobile: mobile };
        axios.post(`${SERVER_URL}/sendotp`, data).then((res) => {
          let type = res.data.msg.type;
          if (type === "success") {
            setDisable(true);
            document.getElementById("otp_sent").style.display = "block";
            // document.getElementById("otp_senttonumber").style.display = "block";
            // otp_sent;
            otpfocus.current.focus();
            document.getElementById("resendlink").style.pointerEvents = "auto";
          } else {
            alert("internal error");
          }
        });
      } else {
        toast.error("Please Enter a valid Mobile Number", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // TO Resend OTP
  const resendOTP = () => {
    let sendNumber = number;

    const data = {
      mobile: sendNumber,
    };

    axios.post(`${SERVER_URL}/resendOtp`, data).then((res) => {
      let type = res.data.msg.type;
      if (type === "success") {
        setDisable(true);
        document.getElementById("otp_sent").style.display = "none";
        document.getElementById("otp_re_sent").style.display = "block";
        otpfocus.current.focus();
      } else if (type === "error") {
        alert("OTP limit reached please try again");
      } else {
        alert("Mainteance Error!!!");
      }
    });
  };

  // Handle Change for OTP
  const handleChangeOTP = (e) => {
    setotp(e.target.value);
    let otpverify = e.target.value;
    if (otpverify.length === 4) {
      verifyOtp(otpverify);
    }
  };

  const resetOTP = () => {
    setotp("");
  };

  const verifyOtp = (otpverify) => {
    let sendNumber = number;

    const data = {
      mobile: sendNumber,
      otp: otpverify,
    };

    axios.post(`${SERVER_URL}/verifyOtp`, data).then((res) => {
      let type = res.data.msg.type;
      if (type === "success") {
        setDisable(false);
        document.getElementById("otp_verified").style.display = "block";
        document.getElementById("otp_mismatch").style.display = "none";
        // document.getElementById("signup").style.pointerEvents = "auto";
        document.getElementById("signup").style.display = "block";
      } else if (type === "error") {
        setDisable(true);
        resetOTP();
        document.getElementById("otp_mismatch").style.display = "block";
        document.getElementById("otp_verified").style.display = "none";
      }
    });
  };

  const SaveUserData = (data, resetForm) => {
    const adduser = {
      fullname: data.fullname,
      email: data.email,
      mobile: number,
    };
    // console.log("user", adduser);

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${SERVER_URL}/adddmpartner`, adduser, {
        headers,
      })
      .then((res) => {
        let Status = res.data.status;
        if (Status === "success") {
          setLoader(false);

          window.$("#AddUserPopUp").modal("hide");
          // window.$("#thankyounew").modal("show");
          navigate("/thankyou");
          resetForm({ data: "" });
          localStorage.setItem("User", number);
          setnumber("");
          setotp("");
        } else if (Status === "failed") {
          toast.error("Details are Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          resetForm({ data: "" });
          setnumber("");
          setotp("");
        }
      });
  };

  const SaveUserDataforwaitlist = (data, resetForm) => {
    const addusers = {
      email: data.email,
    };
    // console.log("user", adduser);
    console.log("user");

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${SERVER_URL}/adddmpartner`, addusers, {
        headers,
      })
      .then((res) => {
        let Status = res.data.status;
        if (Status === "success") {
          window.$("#appNotifications").modal("hide");
          window.$("#thankyounew").modal("show");
          resetForm({ data: "" });
          localStorage.setItem("User", number);
          setnumber("");
          setotp("");
        } else if (Status === "failed") {
          toast.error("Details are Already Exists", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          resetForm({ data: "" });
          setnumber("");
          setotp("");
        }
      });
  };
  const smoothScroll = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const videoplay = () => {
    setvideoactive(true);
    if (!videoactive) {
      videoref.current.scrollIntoView({ behavior: "smooth" });
    } else {
      videoref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // function for to chek user has already sent details switching modals
  const handleUserPopUp = () => {
    var user = localStorage.getItem("User");
    if (user === "" || user === null) {
      window.$("#AddUserPopUp").modal("show");
    } else {
      // window.$("#thankyounew").modal("show");
      navigate("/thankyou");
    }
  };

  // function for to app popup and open userpopup,chek user has already sent details switching modals
  const apphandleUserPopUp = () => {
    var user = localStorage.getItem("User");
    if (user === "" || user === null) {
      window.$("#appNotifications").modal("hide");
      window.$("#AddUserPopUp").modal("show");
    } else {
      window.$("#appNotifications").modal("hide");
      window.$("#thankyou").modal("show");
    }
  };
  const navigatetocendrol = () => {
    window.location.href = "https://cendrol.com";
  };
  // window.addEventListener("scroll", (e) => {
  //   console.log(e, "location");
  // });
  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    let term = queryParams.get("howitworksvideo");

    if (term) {
      window.location = "#active";
      setAuto(true);
      setvideoactive(true);
    }
  }, []);
  return (
    <div>
      <div className="div_overflow">
        <div
          style={{
            background:
              "linear-gradient(286.09deg, #FFF4D1 -4.1%, rgba(255, 244, 209, 0.47) 129.05%)",
          }}
        >
          <div>
            {/* <LandNav /> */}
            <div
              className="row justify-content-center"
              style={{ borderBottom: "1px solid rgba(14, 14, 14, 0.08)" }}
            >
              <div className="col-11 col-md-11">
                <nav className="navbar navbar-expand-lg navbar-light py-3">
                  <div className="container-fluid">
                    <a href="https://www.cendrol.com">
                      <img
                        src={Logo}
                        className="cendrol_log"
                        alt=""
                        onClick={{ navigatetocendrol }}
                      />
                    </a>

                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarTogglerDemo02"
                      aria-controls="navbarTogglerDemo02"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon  "></span>
                    </button>
                    {/*  tablet view start copy code */}

                    {/* tablet view end copy code */}
                    <div
                      className="collapse navbar-collapse "
                      id="navbarTogglerDemo02"
                    >
                      {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0 px-2"> */}
                      <ul className="navbar-nav mb-2 mb-lg-0 ">
                        <li
                          className="nav-item px-3 ull"
                          onClick={() => toggleTab(3)}
                        >
                          <a
                            className={
                              toggleState === 3
                                ? "nav-link nav-link-active ull"
                                : "nav-link"
                            }
                            href="#faq"
                          >
                            {/* FAQ's */}
                          </a>
                        </li>
                        <li
                          className="nav-item px-3"
                          onClick={() => toggleTab(4)}
                        >
                          <a
                            className={
                              toggleState === 4
                                ? "nav-link nav-link-active"
                                : "nav-link ull"
                            }
                            href="#features"
                          >
                            {/* Features */}
                          </a>
                        </li>
                        <li
                          className="nav-item align-self-center px-3"
                          style={{ listStyleImage: "unset" }}
                        >
                          <a
                            className="align-items-center cust_a cust_vline"
                            href="mailto:partner@cendrol.com"
                            title="partner@cendrol.com"
                            style={{ display: "flex" }}
                          >
                            {/* <DraftsIcon className="nav_mail_icons" /> */}
                            <svg
                              className="cust_svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.9395 3C18.2805 3 19.5705 3.53 20.5195 4.481C21.4695 5.43 22.0005 6.71 22.0005 8.05V15.95C22.0005 18.74 19.7305 21 16.9395 21H7.06049C4.26949 21 2.00049 18.74 2.00049 15.95V8.05C2.00049 5.26 4.25949 3 7.06049 3H16.9395ZM18.5305 9.54L18.6105 9.46C18.8495 9.17 18.8495 8.75 18.5995 8.46C18.4605 8.311 18.2695 8.22 18.0705 8.2C17.8605 8.189 17.6605 8.26 17.5095 8.4L13.0005 12C12.4205 12.481 11.5895 12.481 11.0005 12L6.50049 8.4C6.18949 8.17 5.75949 8.2 5.50049 8.47C5.23049 8.74 5.20049 9.17 5.42949 9.47L5.56049 9.6L10.1105 13.15C10.6705 13.59 11.3495 13.83 12.0605 13.83C12.7695 13.83 13.4605 13.59 14.0195 13.15L18.5305 9.54Z"
                                fill="#F7BA00"
                              />
                            </svg>
                            partner@cendrol.com
                          </a>
                        </li>

                        <li
                          className="nav-item align-self-center"
                          style={{ listStyleImage: "unset" }}
                        >
                          <a
                            className="align-items-center cust_a"
                            href="tel:9632888477"
                            title="+91 9632888477"
                            style={{ display: "flex" }}
                          >
                            <svg
                              className="cust_svg"
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.615 4.80378C12.1985 4.72684 11.8159 4.99265 11.7366 5.39925C11.6573 5.80585 11.924 6.20282 12.3292 6.28239C13.5492 6.52023 14.4912 7.46458 14.7299 8.68874V8.68961C14.7979 9.042 15.1081 9.2982 15.4654 9.2982C15.5133 9.2982 15.5613 9.29383 15.6101 9.28508C16.0153 9.20376 16.2819 8.80766 16.2026 8.40019C15.8462 6.57182 14.4389 5.15966 12.615 4.80378"
                                fill="#FDC315"
                              />
                              <path
                                d="M12.5609 1.75694C12.3657 1.72896 12.1696 1.78667 12.0136 1.91083C11.8533 2.03675 11.7531 2.21862 11.7313 2.42236C11.6851 2.8342 11.9823 3.20669 12.3936 3.25304C15.23 3.56957 17.4347 5.77918 17.7536 8.62447C17.7963 9.00571 18.1161 9.29339 18.4978 9.29339C18.5266 9.29339 18.5545 9.29164 18.5832 9.28814C18.7828 9.26628 18.9605 9.16748 19.086 9.01008C19.2106 8.85269 19.2673 8.65683 19.2446 8.45659C18.8473 4.90653 16.0997 2.15129 12.5609 1.75694"
                                fill="#FDC315"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.65277 11.3508C13.1432 14.8403 13.9351 10.8034 16.1574 13.0242C18.3 15.1662 19.5314 15.5953 16.8168 18.3091C16.4768 18.5824 14.3164 21.87 6.72403 14.2797C-0.869294 6.6885 2.41639 4.52589 2.68972 4.18596C5.41088 1.46462 5.83263 2.70321 7.97518 4.84516C10.1976 7.06693 6.16233 7.86136 9.65277 11.3508Z"
                                fill="#0A0A0A"
                              />
                            </svg>
                            +91 9632888477
                          </a>
                        </li>
                      </ul>
                      <div
                        className="d-flex justify-content-around cust_div"
                        style={{ gap: "20px" }}
                      >
                        <div>
                          {/* <button
                            className="btn btn-lg land_nav_gett_app"
                            // onClick={handleUserPopUp}
                            onClick={() => {
                              DownlodAPPref.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            }}
                          >
                            Get the App
                          </button> */}
                        </div>
                        <div>
                          {/* <button
                      className="btn btn-lg land_nav_gett_app"
                      // onClick={handleUserPopUp}
                      // onClick={() => {
                      //   DownlodAPPref.current.scrollIntoView({
                      //     behavior: "smooth",
                      //   });
                      // }}
                    >
                      Get the App
                    </button> */}
                          <button
                            className="cust_btn"
                            onClick={() => {
                              trackEvent("Android App Clicked", {
                                button_label: "Android App Clicked",
                              });
                              window.open(
                                "https://play.google.com/store/search?q=cendrol%20partner&c=apps"
                              );
                            }}
                          >
                            <img src={playsore} alt="" />
                          </button>
                        </div>
                        <div>
                          {/* <button
                      className="btn btn-lg land_nav_gett_app"
                      // onClick={handleUserPopUp}
                      // onClick={() => {
                      //   DownlodAPPref.current.scrollIntoView({
                      //     behavior: "smooth",
                      //   });
                      // }}
                    >
                      Get the App
                    </button> */}
                          <button
                            className="cust_btn"
                            onClick={() => {
                              trackEvent("IOS App Clicked", {
                                button_label: "IOS App Clicked",
                              });
                              window.open(
                                "https://apps.apple.com/in/app/cendrol-partner/id6445860874"
                              );
                            }}
                          >
                            <img src={apple} alt="" />
                          </button>
                        </div>
                        <div>
                          <button
                            className="btn btn-lg land_nav_signup"
                            // onClick={handleUserPopUp}
                            // data-bs-toggle="modal"
                            // data-bs-target=".bd-example-modal-user"
                            onClick={() => {
                              trackEvent("Clicked on  Login / Signup", {
                                button_label: "Clicked on  Login / Signup",
                              });
                              window.open(
                                "https://www.partnerlogin.cendrol.com/"
                              );
                            }}
                          >
                            Login / Signup
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <div id="home">
            {/* LandFirstSection Start */}
            <div className="row justify-content-center">
              <div className="col-11 col-md-11">
                <div className="">
                  <div className="row py-4">
                    <div className="col-12 col-sm-12 col-md-7 align-self-center hero_desktop_carousel">
                      <div
                        id="carouselExampleSlidesOnly"
                        className="carousel slide"
                        data-bs-ride="carousel"
                        data-bs-interval="3000"
                      >
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div className="land_first_section_head_txt">
                              <span>
                                You don't need to be
                                <br />
                                a Real Estate agent to
                                <br />
                                earn 1 Lakh & more!
                              </span>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="land_first_section_head_txt">
                              <span>
                                Got contacts?
                                <br />
                                Earn 1 Lakh &
                                <br />
                                Even More!
                              </span>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="land_first_section_head_txt">
                              <span>
                                Paid over 50 Lakhs to
                                <br />
                                Thousands of our
                                <br />
                                Referral Partners
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pt-3 land_first_section_sub_txt">
                        <span>
                          Join the program, refer a plot owner and earn ₹1 Lakh
                          &
                          <br />
                          more on every referral
                        </span>
                      </div>

                      <div className="pt-4 d-flex" style={{ gap: "20px" }}>
                        <button
                          className="btn bnt-lg land_first_getStarted"
                          onClick={() => {
                            handleUserPopUp();
                            trackEvent("Get Started  clicked", {
                              button_label: "Get Started  clicked",
                            });
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target=".bd-example-modal-user"
                        >
                          Get Started
                          {/* <ArrowRightAltSharpIcon className="land_btn_icon_get" /> */}
                          <ArrowBackIcon className="land_btn_icon_get" />
                        </button>
                        <button
                          className="btn bnt-lg land_how_works"
                          style={{ gap: "10%" }}
                          onClick={() => {
                            videoplay();
                            trackEvent("How It Works", {
                              button_label: "How It Works",
                            });
                          }}
                        >
                          <PlayArrowRoundedIcon className="land_btn_icon_play" />
                          How it Works ?
                        </button>
                      </div>
                      {/* <button >sharewhatsapp</button> */}
                    </div>
                    <div className="col-12 col-sm-12 col-md-7 justify-content-center hero_mob_text">
                      <div className="land_first_section_head_txt">
                        <span>
                          You don't need to <br /> be a Real Estate agent to
                          earn 1 Lakh & more!
                        </span>
                      </div>
                      <div className="pt-3 land_first_section_sub_txt">
                        <span>
                          Join the program, refer a plot owner and earn <br />{" "}
                          ₹1 Lakh & more on every referral
                        </span>
                      </div>
                      <div className="pt-4 d-flex" style={{ gap: "20px" }}>
                        <button
                          className="btn bnt-lg land_first_getStarted"
                          onClick={() => {
                            handleUserPopUp();
                            trackEvent("Get Started  clicked", {
                              button_label: "Get Started  clicked",
                            });
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target=".bd-example-modal-user"
                        >
                          Get Started
                          {/* <ArrowRightAltSharpIcon className="land_btn_icon_get" /> */}
                          <ArrowBackIcon className="land_btn_icon_get" />
                        </button>
                        <button
                          className="btn bnt-lg land_how_works"
                          style={{ gap: "10%" }}
                          onClick={() => {
                            videoplay();
                            trackEvent("How It Works", {
                              button_label: "How It Works",
                            });
                          }}
                        >
                          <PlayArrowRoundedIcon className="land_btn_icon_play" />
                          How it Works ?
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-5">
                      <img
                        src={Hero}
                        alt=""
                        className="img-fluid hero_image_first_section"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* LandFirstSection End */}

            {/* <LandFirstSection /> */}
            <div className="w-100 text-center pb-3">
              <CgMouse className="mouse_pointer" onClick={smoothScroll} />
            </div>
          </div>
        </div>

        {/* Video Start */}
        <div ref={videoref} id="active">
          <div ref={ref}>
            <LandVideo autoplay={auto} />
          </div>
        </div>

        {/* Video End */}

        {/* Land Earning Start */}
        <div id="features">
          <LandEarning />
        </div>
        {/* Land Earning End */}

        {/* Join Program Start */}
        <div id="benefits" style={{ background: "#F9F9F9" }}>
          {/* <LandJoinProgram /> */}
          <div className="row justify-content-center">
            <div className="col-11 col-md-11">
              <div className="container-fluid">
                <div className="row pb-5">
                  <div className="pt-5 col-12 col-sm-12 col-md-5">
                    <div className="land_joinProgram_head_txt">
                      <span>
                        Why should you join
                        <br />
                        the program?
                      </span>
                    </div>

                    <div className="pt-3 land_joinProgram_sub_txt">
                      <span>
                        Here are four reasons why your referral payment
                        <br />
                        is around the corner.
                      </span>
                    </div>

                    <div className="pt-4">
                      <button
                        className="btn bnt-lg land_join_getStarted"
                        onClick={() => {
                          handleUserPopUp();
                          trackEvent("Get Started  clicked", {
                            button_label: "Get Started  clicked",
                          });
                        }}
                        // data-bs-toggle="modal"
                        // data-bs-target=".bd-example-modal-user"
                      >
                        Get Started
                        {/* <ArrowRightAltSharpIcon className="land_btn_icon_get" /> */}
                        <ArrowBackIcon className="land_btn_icon_get" />
                      </button>
                    </div>
                  </div>

                  <div className="pt-5 col-12 col-sm-12 col-md-7">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6">
                        <div className="land_join_col">
                          <div className="land_joining_icons p-2">
                            <img src={feat1} className="feat_imgs" />
                          </div>

                          <div className="land_join_text_top land_join_icon_txt">
                            <span>
                              Honest commission <br />
                              Flat ₹ 30 - ₹ 50 per square feet
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6">
                        <div className="land_join_col">
                          <div className="land_joining_icons p-2">
                            <img src={feat2} className="feat_imgs" />
                          </div>
                          <div className="land_join_text_top land_join_icon_txt">
                            <span>
                              Don’t miss out on
                              <br />
                              Real-time lead updates!
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 land_join_second_row">
                        <div className="land_join_col">
                          <div className="land_joining_icons p-2">
                            <img src={feat3} className="feat_imgs" />
                          </div>
                          <div className="land_join_text_top land_join_icon_txt">
                            <span>
                              Get initial payment in <br /> 48 hours from the{" "}
                              <br />
                              project start date
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-4 land_join_second_row">
                        <div className="land_join_col">
                          <div className="land_joining_icons p-2">
                            <img src={feat4} className="feat_imgs" />
                          </div>

                          <div className="land_join_icon_txt land_join_text_top">
                            <span>
                              Partner with best in the <br /> construction
                              industry
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Join Program End */}

        {/* Calculator start */}
        <div>
          <LandCalculator />
        </div>
        {/* Calculator end */}

        {/* Card Carsoul start */}
        <div id="blog" style={{}}>
          <LandCardCarsoul />
        </div>
        {/* Card Carsoul end */}

        {/* FAQ start */}
        <div id="faq">
          <LandFAQ />
        </div>
        {/* FAQ end */}

        {/* Ready to Partner start */}
        <div>
          {/* <ReadyTOPartner /> */}
          <div className="row justify-content-center">
            <div className="col-11 col-md-11">
              <div className="">
                <div className="ready_tobepat">
                  <div className="ready-to-partner">
                    <div className="">
                      <div className="ready_partner_head_txt align-self-center">
                        <span>Ready To Be a Partner?</span>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn btn-lg sign_up_free_btn"
                          onClick={() => {
                            handleUserPopUp();
                            trackEvent("Sign Up For Free Clicked", {
                              button_label: "Sign Up For Free Clicked",
                            });
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target=".bd-example-modal-user"
                        >
                          Sign Up For Free
                          <ArrowRightAltSharpIcon className="land_sign_free_icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Ready to Partner end */}

        {/* Referring Part start */}
        <div>
          {/* <Refering /> */}
          <div className="row justify-content-center">
            <div className="col-11 col-md-11">
              <div className="container-fluid">
                <div className="row land_refer">
                  <div className="pt-5 col-12 col-sm-12 col-md-6">
                    <div className="referring_head_txt">
                      <span>
                        No Compromise, just maximize.
                        <br />
                        Download the app now!
                      </span>
                    </div>
                    <div className="pt-3 referring_sub_txt">
                      <span>
                        Referring can’t wait! Join the waitlist and <br />{" "}
                        Download the app Now !!!
                      </span>
                    </div>
                    <div className="pt-4 button_centering mb-4  ">
                      <button
                        className="btn_new"
                        onClick={() => {
                          trackEvent("IOS App Clicked", {
                            button_label: "IOS App Clicked",
                          });
                          window.open(
                            "https://apps.apple.com/in/app/cendrol-partner/id6445860874"
                          );
                        }}
                      >
                        <img src={applestore_1} />
                      </button>
                      <button
                        className="btn_new"
                        onClick={() => {
                          trackEvent("Android App Clicked", {
                            button_label: "Android App Clicked",
                          });
                          window.open(
                            "https://play.google.com/store/search?q=cendrol%20partner&c=apps"
                          );
                        }}
                      >
                        <img src={playstore_1} />
                      </button>
                    </div>
                  </div>
                  <div className="pt-5 col-12 col-sm-12 col-md-6 display_footer_end">
                    <img src={Mobile} className="refer_mob_img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Referring Part End */}

        {/* Footer Part start */}
        <div>
          {/* <Footer /> */}
          <div
            className="row justify-content-center"
            style={{ background: "#F9F9F9" }}
          >
            <div className="col-11 col-md-11">
              <div className="container-fluid">
                <div className="pb-5">
                  <div className="land_footer_divider_line">
                    <div className="pb-5 row">
                      <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_start">
                        <div className="d-flex">
                          <div className="land_multiple_icons p-2">
                            <RiWallet3Fill className="land_colored_icons" />
                          </div>
                          <div className="land_footer_icon_with_text">
                            <span>
                              Get initial payment in <br /> 48 hours from the
                              project start date{" "}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_center">
                        <div className="d-flex">
                          <div className="land_multiple_icons p-2">
                            <NotificationsSharpIcon className="land_colored_icons" />
                          </div>
                          <div className="land_footer_icon_with_text">
                            <span>
                              Don’t miss out <br /> Real-time lead updates!
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_end">
                        <div className="d-flex">
                          <div className="land_multiple_icons p-2">
                            <CurrencyRupeeIcon className="land_colored_icons" />
                          </div>
                          <div className="land_footer_icon_with_text">
                            <span>
                              Honest commission <br /> Flat ₹ 30- ₹ 50 per
                              square feet
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_start">
                      <div>
                        <div className="land_footer_text_heads">
                          <span>Start a Conversation</span>
                        </div>
                        <div className="pt-4 land_footer_mail">
                          <a
                            onClick={() =>
                              trackEvent("Clicked On Mail", {
                                button_label: "Clicked On Mail",
                              })
                            }
                            href="mailto:partner@cendrol.com"
                            title="partner@cendrol.com"
                            // className="land_footer_mail"
                          >
                            <span>partner@cendrol.com</span>
                          </a>
                          {/* <span>sales@cendrol.com</span> */}
                          <span>
                            <ArrowRightAltSharpIcon className="land_footer_btn_icon_get" />
                          </span>
                        </div>
                        <br />
                        {/* <Link
                          to={`/privacy`}
                          target="_blank"
                          className="mt-3 PrivacyPolicy "
                        >
                          Privacy Policy
                        </Link> */}

                        <Link
                          className="mt-3 PrivacyPolicy land_footer_mail"  
                          to={`/terms-conditions`}
                        >
                          Terms & Conditions
                        </Link>
            
                    
                        <br />
                        <Link
                          className="mt-3 PrivacyPolicy land_footer_mail"
                          to={`/terms-services`}
                        >
                          Terms Of Service
                        </Link>
                        <br />
                        <Link
                          className="mt-3 PrivacyPolicy  land_footer_mail"
                          to={`/privacypolicy`}
                        >
                          Privacy Policy
                        </Link>
                      </div>
                    </div>

                    <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_center">
                      <div>
                        <div className="land_footer_text_heads">
                          <span>General</span>
                        </div>
                        <div className="pt-4 land_footer_number  ">
                          <a
                            onClick={() =>
                              trackEvent("Call Button Clicked", {
                                button_label: "Call Button Clicked",
                              })
                            }
                            href="tel:9632888477"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="+91 9632888477"
                            className="land_footer_number land-foot"
                          >
                            +91 9632888477
                          </a>
                        </div>
                        <div className="pt-4 land_footer_icons">
                          <a
                            onClick={() =>
                              trackEvent("twitter", {
                                button_label: "twitter",
                              })
                            }
                            href="https://twitter.com/CendrolPartners"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="twitter"
                            className="land_footer_icons"
                          >
                            <TwitterIcon />
                          </a>

                          <a
                            onClick={() =>
                              trackEvent("linkedin", {
                                button_label: "linkedin",
                              })
                            }
                            href="https://www.linkedin.com/company/cendrol-partners"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="linkedin"
                            className="land_footer_icons"
                          >
                            <LinkedInIcon />
                          </a>

                          <a
                            onClick={() =>
                              trackEvent("Instagram", {
                                button_label: "Instagram",
                              })
                            }
                            href="https://instagram.com/cendrolpartner?igshid=YmMyMTA2M2Y="
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Instagram"
                            className="land_footer_icons"
                          >
                            <InstagramIcon />
                          </a>

                          <a
                            onClick={() =>
                              trackEvent("facebook", {
                                button_label: "facebook",
                              })
                            }
                            href="https://m.facebook.com/cendrolpartners/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="facebook"
                            className="land_footer_icons"
                          >
                            <FacebookSharpIcon />
                          </a>

                          <a
                            onClick={() =>
                              trackEvent("Youtube", {
                                button_label: "Youtube",
                              })
                            }
                            href="https://www.youtube.com/@cendrolpartners"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Youtube"
                            className="land_footer_icons"
                          >
                            <YouTubeIcon />
                          </a>
                          <a
                            onClick={() =>
                              trackEvent("Pinterest", {
                                button_label: "Pinterest",
                              })
                            }
                            href="https://pin.it/5cJyHsR"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Pinterest"
                            className="land_footer_icons"
                          >
                            <PinterestIcon />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_end">
                      <div>
                        <div className="land_footer_text_heads">
                          <span>Office</span>
                        </div>
                        <div className="pt-3 land_footer_number">
                          <span>
                            Cendrol Construction Contracts PVT LTD <br />
                          </span>
                          <span>
                            3rd floor, Cendrol, 299, 288, Outer Ring Rd, J. P.
                            Nagar, Bengaluru, Karnataka 560078
                          </span>
                        </div>

                        {/* <div className="pt-4 land_footer_privacy_policy">
                          <span>Privacy Policy | Terms & Conditions</span>
                        </div> */}
                      </div>
                    </div>

                    {/* <div className="pt-5 col-12 col-sm-12 col-md-3 display_footer_end">
                      <div>
                        <span onClick={() => toggleTab1(1)}>
                          <a
                            className={
                              toggleState1 === 1
                                ? "land_footer_nav_items land_footer_nav_items_active"
                                : "land_footer_nav_items"
                            }
                            href="#home"
                          >
                            Home
                          </a>
                        </span>
                        <br />
                        <span onClick={() => toggleTab1(2)}>
                          <a
                            className={
                              toggleState1 === 2
                                ? "land_footer_nav_items land_footer_nav_items_active"
                                : "land_footer_nav_items"
                            }
                            href="#benefits"
                          >
                            Benefits
                          </a>
                        </span>
                        <br />
                        <span onClick={() => toggleTab1(3)}>
                          <a
                            className={
                              toggleState1 === 3
                                ? "land_footer_nav_items land_footer_nav_items_active"
                                : "land_footer_nav_items"
                            }
                            href="#faq"
                          >
                            FAQ's
                          </a>
                        </span>
                        <br />
                        <span onClick={() => toggleTab1(4)}>
                          <a
                            className={
                              toggleState1 === 4
                                ? "land_footer_nav_items land_footer_nav_items_active"
                                : "land_footer_nav_items"
                            }
                            href="#features"
                          >
                            Features
                          </a>
                        </span>
                        <br />
                        <span
                          className="land_footer_nav_items"
                          style={{ cursor: "pointer" }}
                          onClick={videoplay}
                        >
                         How It Works
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div_overflow footer">
          <div className="boxCopyright">
            <div className="container">
              <p className="mb-0">
                © {new Date().getFullYear()} www.cendrol.com All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Part End */}

      {/* POP Up for user Data start */}
      <div
        id="AddUserPopUp"
        className="modal fade bd-example-modal-user"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered  ">
          <div
            className="modal-content modal_content_whole"
            style={{ background: "white" }}
          >
            <div className="modal-body">
              <div className="d-flex justify-content-between form_head_txt">
                <div className="">
                  <span>Enter Your Details</span>
                </div>
                {/* <div type="button" data-bs-dismiss="modal" aria-label="Close">
                  X
                </div> */}
              </div>

              <div>
                {/* Add User Pop Up Content start */}
                <Formik
                  initialValues={{
                    fullname: "",
                    email: "",
                    number: "",
                  }}
                  onSubmit={(values, { resetForm }) => {
                    SaveUserData(values, resetForm);
                    setLoader(true);
                    setDisable(true);
                    setnumber("");
                    setotp("");
                  }}
                >
                  {({ errors, touched }) => (
                    <div>
                      <Form>
                        <div className="pt-5">
                          <div className="pb-3">
                            <label
                              htmlFor="fullName"
                              className="user_form_label"
                            >
                              Full Name
                            </label>

                            <Field
                              type="text"
                              name="fullname"
                              className="form-control user_form_fileds"
                              id="fullName"
                              placeholder="Enter Full Name"
                              autoComplete="off"
                              validate={validateName}
                            />
                            {errors.fullname && touched.fullname && (
                              <span className="errors">{errors.fullname}</span>
                            )}
                          </div>

                          <div className="pb-3">
                            <label htmlFor="email" className="user_form_label">
                              Email
                            </label>
                            <Field
                              type="email"
                              name="email"
                              className="form-control user_form_fileds"
                              id="email"
                              placeholder="Email"
                              autoComplete="off"
                              validate={validateEmail}
                            />
                            {errors.email && touched.email && (
                              <span className="errors">{errors.email}</span>
                            )}
                          </div>

                          <div className="pb-3">
                            <label htmlFor="number" className="user_form_label">
                              Phone Number
                            </label>
                            <div className="d-flex">
                              <span className="number_edit_91">+91</span>
                              <input
                                className="form-control user_form_fileds"
                                id="number"
                                name="number"
                                placeholder="Enter Mobile Number"
                                autoComplete="off"
                                value={number}
                                type="number"
                                onKeyDown={(event) => {
                                  const value = event.target.value;
                                  if (
                                    value.length >= 10 &&
                                    event.keyCode !== 8
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(e) => handleChangeNumber(e)}
                                validate={validateMobileNumber}
                              />

                              {errors.number && touched.number && (
                                <span className="errors">{errors.number}</span>
                              )}
                            </div>

                            <span
                              id="otp_sent"
                              className="otp_sent"
                              style={{ display: "none" }}
                            >
                              OTP Sent
                            </span>
                          </div>

                          <div className="pb-3" ref={otpfocus}>
                            <label htmlFor="otp" className="user_form_label">
                              Enter OTP
                            </label>
                            <Field
                              type="text"
                              name="otp"
                              className="form-control user_form_fileds"
                              id="otp"
                              placeholder="Enter OTP"
                              autoComplete="off"
                              value={otp}
                              onChange={(e) => handleChangeOTP(e)}
                            />
                            <span
                              id="otp_verified"
                              className="otp_sent"
                              style={{ display: "none" }}
                            >
                              OTP verified
                            </span>
                            <span
                              id="otp_mismatch"
                              className="otp_worong"
                              style={{ display: "none" }}
                            >
                              Wrong OTP...ReEnter
                            </span>
                          </div>

                          <div className="pb-3">
                            <span className="form_OTP_not">
                              Did not receive OTP ?
                            </span>
                            <span
                              id="resendlink"
                              className="resend_otp"
                              onClick={() => {
                                resendOTP(number);
                                console.log("hi");
                              }}
                            >
                              {" "}
                              Resend OTP
                            </span>
                          </div>

                          <div>
                            <button
                              id="signup"
                              type="submit"
                              disabled={disable}
                              className="btn btn-lg form_signup_btn d-flex justify-content-center"
                            >
                              {loader && <Loader />}
                              <p className="mb-0 px-4">Sign Up</p>
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* POP Up for user Data End */}

      {/* POP Up for Mobile App Start */}
      <div
        id="appNotifications"
        className="modal fade modal_appNotification"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content modal-lg  modal_content_whole mob_app_popup">
            <div className="modal-body">
              <div
                type="button"
                className="mob_app_close_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </div>
              <div className="pt-2 row">
                <div className="col-12 col-sm-12 col-md-4">
                  <img className="mob_app" src={App} />
                </div>
                <div className="col-12 col-sm-12 col-md-8 align-self-center">
                  <div className="mob_app_head_txt">
                    <span>Good Things Take Time</span>
                  </div>
                  <div className="pt-3 mob_app_sub_text">
                    <span>Join the waitlist and get notified once launch</span>
                  </div>
                  <Formik
                    initialValues={{
                      email: "",
                      // number: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                      SaveUserDataforwaitlist(values, resetForm);
                    }}
                  >
                    {({ errors, touched }) => (
                      <div>
                        <Form>
                          <div className="pt-4">
                            <Field
                              type="email"
                              className="mob_app_sub_text waitlist_input_tage"
                              placeholder="Enter Your Email"
                              name="email"
                              handleUserPopUp
                              validate={validateEmail}
                            />
                            <br />
                            {errors.email && touched.email && (
                              <span className="errors">{errors.email}</span>
                            )}
                            <br />
                            <button
                              className="btn btn-lg mob_app_btn"
                              // onClick={apphandleUserPopUp}
                            >
                              <AddIcon /> Join The Waitlist
                            </button>
                          </div>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* POP Up for Mobile App Start */}

      {/* Thank You Page */}
      <div
        id="thankyou"
        className="modal fade modal_thankyou"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal_content_whole mob_app_popup">
            <div className="modal-body">
              <div
                type="button"
                className="mob_app_close_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </div>
              <div className="text-center">
                <img className="img_thank_you" src={ThankYou} />
              </div>
              <div className="pt-4 thank_you_head_txt">
                <span>Thank You</span>
              </div>
              <div className="pt-3 thank_you_sub_txt">
                <span>
                  We are Choosing The Best Sales Person to Assist You Soon
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* POP Up for Mobile App Start */}

      {/* Updated thankyou popup */}
      <div
        id="thankyounew"
        className="modal fade "
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal_content_whole mob_app_popup thankyounew_up">
            <div className="modal-body">
              <div
                type="button"
                className="mob_app_close_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </div>
              <div className="thankyou_updated">
                <div className="text-center">
                  <div className="pt-4 ">
                    <h3>Thank You!</h3>
                    <div className="thank_you_sub_txt">
                      <span className="waitlist_text">
                        We have added you on the waiting list.
                      </span>
                    </div>
                    <div className="mt-3 row justify-content-center">
                      <div className="col-12 col-md-10">
                        <div className="ticket_div d-flex">
                          <div className="ppl_ahead">
                            {Math.floor(1000 + Math.random() * 9000)}
                            <p className="mb-0 ticket_ppl_count">
                              Partners ahead of you
                            </p>
                          </div>
                          <div className="ppl_ahead" style={{ border: "none" }}>
                            {Math.floor(10000 + Math.random() * 90000)}
                            <p className="mb-0 ticket_ppl_count">
                              Partners Behind <br /> you
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 p-3">
                      <p className="waitlist_text">
                        Interested in Priority Access??
                      </p>
                      <p className="waitlist_text">
                        Get early access of the App by referring more. Download
                        the beta version for early offers.
                      </p>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-10">
                        <div className="p-3">
                          <div className="d-flex mb-2">
                            <div className="copy_link_sec">
                              https://www.partner.cendrol.com
                            </div>
                            {/* <div
                              className="copy_link_btn"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  " https://www.partner.cendrol.com"
                                )
                              }
                            >
                              Copy Link
                            </div> */}
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <div>
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={open}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title="Copied"
                                >
                                  <button
                                    className="copy_link_btn_cover"
                                    onClick={handleTooltipOpen}
                                  >
                                    <div
                                      className="copy_link_btn"
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          " https://www.partner.cendrol.com"
                                        )
                                      }
                                    >
                                      Copy Link
                                    </div>
                                  </button>
                                </Tooltip>
                              </div>
                            </ClickAwayListener>
                          </div>
                          <div className="text-center">
                            <span className="waitlist_text">
                              Or Share in Whatsapp
                            </span>
                            <div
                              className="d-flex justify-content-center align-items-center mt-2"
                              style={{
                                background: "#F8F8F8",
                                borderRadius: "2px",
                                width: "45px",
                                height: "40px",
                                margin: "auto",
                                padding: "5px",
                              }}
                            >
                              <img
                                onClick={sharewhatsapp}
                                src={logos_whatsapp}
                                width="90%"
                                className=""
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="text-center">
                <img className="img_thank_you" src={ThankYou} />
              </div>
              <div className="pt-4 thank_you_head_txt">
                <span>Thank You</span>
              </div>
              <div className="pt-3 thank_you_sub_txt">
                <span>
                  We are Choosing The Best Sales Person to Assist You Soon
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
