import React from "react";
import FAQImg from "../../Assests/Faq_img.png";
import { useState } from "react";
import useAnalytics from "../CustomHook/useAnalytics";
const Land_FAQ = () => {
  const { trackEvent } = useAnalytics();
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div className="row justify-content-center">
      <div className="col-11 col-md-11">
        <div className="container-fluid">
          <div className="row pb-5">
            <div className="pt-5 col-12 col-sm-12 col-md-5 faq_mob">
              <img src={FAQImg} className="land_faq_image" />
            </div>
            <div className="pt-5 col-12 col-sm-12 col-md-7 land_faq_right">
              <div className="land_faq_text">
                <span>
                  Frequently <br />
                  asked questions
                </span>
              </div>
              <div className="d-flex mt-3 faqs_section_button">
                <div className="tab_lead_data_start">
                  <button
                    className={
                      toggleState === 1
                        ? "tab_lead_data active_tab_lead_data"
                        : "tab_lead_data"
                    }
                    onClick={() => {
                      toggleTab(1);
                      trackEvent("Introduction", {
                        button_label: "Introduction",
                      });
                    }}
                  >
                    Introduction
                  </button>
                </div>

                <div className="tab_lead_data_start">
                  <button
                    className={
                      toggleState === 2
                        ? "tab_lead_data active_tab_lead_data"
                        : "tab_lead_data"
                    }
                    onClick={() => {
                      toggleTab(2);
                      trackEvent("Onboarding", {
                        button_label: "Onboarding",
                      });
                    }}
                  >
                    Onboarding
                  </button>
                </div>

                <div className="tab_lead_data_start">
                  <button
                    className={
                      toggleState === 3
                        ? "tab_lead_data active_tab_lead_data"
                        : "tab_lead_data"
                    }
                    onClick={() => {
                      toggleTab(3);
                      trackEvent("Mobile App", {
                        button_label: "Mobile App",
                      });
                    }}
                  >
                    Mobile App
                  </button>
                </div>

                <div className="tab_lead_data_start">
                  <button
                    className={
                      toggleState === 4
                        ? "tab_lead_data active_tab_lead_data"
                        : "tab_lead_data"
                    }
                    onClick={() => {
                      toggleTab(4);
                      trackEvent("Payment", {
                        button_label: "Payment",
                      });
                    }}
                  >
                    Payment
                  </button>
                </div>

                <div className="tab_lead_data_start">
                  <button
                    className={
                      toggleState === 5
                        ? "tab_lead_data active_tab_lead_data"
                        : "tab_lead_data"
                    }
                    onClick={() => {
                      toggleTab(5);
                      trackEvent("Transparency", {
                        button_label: "Transparency",
                      });
                    }}
                  >
                    Transparency
                  </button>
                </div>
              </div>

              <div
                className={
                  toggleState === 1
                    ? "lead-tab-content  lead-tab-active-content"
                    : "lead-tab-content"
                }
              >
                <div className="">
                  <div
                    className="pt-4 accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>01</span>
                              <span className="accordion-title">
                                What is the Cendrol Partner Program?
                                {/* Alright,but what exactly do you do? */}
                              </span>
                            </div>

                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-content">
                          Cendrol Partner Program is a referral program that
                          pays for every lead conversion from your network.
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>02</span>
                              <span className="accordion-title">
                                Who can join the Cendrol Partner Program?
                              </span>
                            </div>
                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>

                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-content">
                          If you have lead contacts, the program is just for
                          you! No other restrictions, all are welcome to join
                          the partner program.
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>03</span>
                              <span className="accordion-title">
                                What is the benefit of joining the Cendrol
                                Partner Program?
                              </span>
                            </div>
                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>

                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-content">
                          Besides your main income, you can also earn on your
                          lead contacts from the program by partnering with us.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  toggleState === 2
                    ? "lead-tab-content  lead-tab-active-content"
                    : "lead-tab-content"
                }
              >
                <div className="">
                  <div
                    className="pt-4 accordion accordion-flush"
                    id="accordionFlushExample2"
                  >
                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>01</span>
                              <span className="accordion-title">
                                How can I get onboarded/join as a Cendrol
                                Partner?
                                {/* Alright,but what exactly do you do? */}
                              </span>
                            </div>

                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample2"
                      >
                        <div className="accordion-content">
                          It’s simple - Register for the Cendrol Partner Program
                          and complete your KYC to start earning.
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>02</span>
                              <span className="accordion-title">
                                What are the documents needed for KYC?
                              </span>
                            </div>
                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>

                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample2"
                      >
                        <div className="accordion-content">
                          To validate, we would need your PAN and Aadhar details
                          as a part of the KYC process.
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>03</span>
                              <span className="accordion-title">
                                What if I join the partner program but I’m
                                unable to provide leads?
                              </span>
                            </div>
                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>

                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample2"
                      >
                        <div className="accordion-content">
                          No worries, you may provide leads whenever you are
                          likely to have them!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  toggleState === 3
                    ? "lead-tab-content  lead-tab-active-content"
                    : "lead-tab-content"
                }
              >
                <div className="">
                  <div
                    className="pt-4 accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>01</span>
                              <span className="accordion-title">
                                What is the Cendrol Partner App?
                                {/* Alright,but what exactly do you do? */}
                              </span>
                            </div>

                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-content">
                          Cendrol Partner App is specially created for you to
                          add and track your lead progress during the partner
                          program. It also allows you to track payments of each
                          lead conversion. You’re in full control!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  toggleState === 4
                    ? "lead-tab-content  lead-tab-active-content"
                    : "lead-tab-content"
                }
              >
                <div className="">
                  <div
                    className="pt-4 accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>01</span>
                              <span className="accordion-title">
                                What are the modes of payment for the fee earned
                                during the partner program?
                                {/* Alright,but what exactly do you do? */}
                              </span>
                            </div>

                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-content">
                          Your earned fee will be credited directly to your bank
                          account shared during the onboarding process.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  toggleState === 5
                    ? "lead-tab-content  lead-tab-active-content"
                    : "lead-tab-content"
                }
              >
                <div className="">
                  <div
                    className="pt-4 accordion accordion-flush"
                    id="accordionFlushExample4"
                  >
                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>01</span>
                              <span className="accordion-title">
                                How do I trust you regarding leads?
                                {/* Alright,but what exactly do you do? */}
                              </span>
                            </div>

                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample4"
                      >
                        <div className="accordion-content">
                          We work on an agreement basis, everything will be
                          documented and transparent, right from the leads to
                          status and the agreement copy. You can always stay
                          updated and track the status of your leads via the
                          Cendrol Partner App.
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>02</span>
                              <span className="accordion-title">
                                I want to negotiate on the percentage?
                              </span>
                            </div>
                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>

                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample4"
                      >
                        <div className="accordion-content">
                          We are open to negotiation depending on the project
                          value and the number of projects provided by the
                          partner.
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>03</span>
                              <span className="accordion-title">
                                How can I track my leads and their progress?
                              </span>
                            </div>
                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>

                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample4"
                      >
                        <div className="accordion-content">
                          We are launching our Cendrol Partner app where you can
                          check the status of your leads, progress, payments and
                          more.
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="land_acc_item">
                        <button
                          className="collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          <div>
                            <div className="d-flex" style={{ gap: "15px" }}>
                              <span>04</span>
                              <span className="accordion-title">
                                How do you convert leads?
                              </span>
                            </div>
                            <span className="icon" aria-hidden="true"></span>
                          </div>
                        </button>
                      </div>

                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample4"
                      >
                        <div className="accordion-content">
                          As soon as we connect with the client, we schedule a
                          meeting to learn about the client's requirements and
                          design needs and share a plan as per their vision to
                          convert the lead into a Cendrol customer.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land_FAQ;
