import React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import CardProfile from "../../Assests/card_profile.png";
import CardProfile1 from "../../Assests/card_profile1.png";
import CardProfile2 from "../../Assests/card_profile2.png";
import CardProfile3 from "../../Assests/card_profile3.png";
import CardProfile4 from "../../Assests/test4.png";
import CardProfile5 from "../../Assests/card_profile5.png";
import StarIcon from "@mui/icons-material/Star";
import "react-multi-carousel/lib/styles.css";

const Land_Card_Carsoul = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-11 col-md-11">
        <div className="container-fluid">
          <div className="py-5 d-none d-lg-block">
            <div className="pb-4 d-flex justify-content-between">
              <div>
                <div className="card_carsoul_head_txt">
                  <span>
                    Don’t take our word for it, here’s what our partners have to
                    say!
                  </span>
                </div>
              </div>

              <div className="align-self-center">
                <div
                  className="d-flex carousel-button-group"
                  style={{ gap: "15px" }}
                >
                  <button
                    className="card_carsoul-prev"
                    type="button"
                    data-bs-target="#multi-item-example"
                    data-bs-slide="prev"
                  >
                    <BsArrowLeft className="card_carsoul_icon" />
                  </button>
                  <button
                    className="card_carsoul-next"
                    type="button"
                    data-bs-target="#multi-item-example"
                    data-bs-slide="next"
                  >
                    <BsArrowRight className="card_carsoul_icon" />
                  </button>
                </div>
              </div>
            </div>

            <div
              id="multi-item-example"
              className="carousel slide carousel-multi-item"
              data-bs-ride="carousel"
              data-bs-interval="5000"
            >
              <div className="carousel-inner d-none d-sm-block">
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3 col-sm-3 col-md-3">
                              <img
                                className="land_card_profile"
                                src={CardProfile1}
                              />
                            </div>
                            <div className="col-9 col-sm-9 col-md-9 align-self-center">
                              <span className="land_card_profile_name">
                                Murari Kumar
                              </span>
                              <br />
                              <span className="land_card_profile_place">
                                Bengaluru
                              </span>
                            </div>
                          </div>
                          <div className="pt-3 card_border_bottom"></div>
                          <div className="pt-3">
                            <span className="land_card_text">
                              My client loved their workmanship after visiting
                              their ongoing and completed projects.
                              <br /> It’s a Win-Win!
                            </span>
                          </div>
                          <div className="rating_box_fixing d-flex justify-content-between">
                            <div>
                              <div
                                className="btn card_carsoul_rating d-flex align-items-center"
                                style={{ gap: "10px" }}
                              >
                                <span>
                                  <StarIcon className="card_carsoul_star" />
                                </span>
                                <span>4.9</span>
                              </div>
                            </div>
                            <div className="align-self-center card_carsoul_date">
                              <span>2nd Feb 2022</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3 col-sm-3 col-md-3">
                              <img
                                className="land_card_profile"
                                src={CardProfile2}
                              />
                            </div>
                            <div className="col-9 col-sm-9 col-md-9 align-self-center">
                              <span className="land_card_profile_name">
                                Prince
                              </span>
                              <br />
                              <span className="land_card_profile_place">
                                Bengaluru
                              </span>
                            </div>
                          </div>
                          <div className="pt-3 card_border_bottom"></div>
                          <div className="pt-3">
                            <span className="land_card_text">
                              Value for ownership from Cendrol’s team in
                              pursuing the referral is outstanding. The quality
                              of materials is top-notch at an unbeatable sq. ft.
                              price.
                            </span>
                          </div>
                          <div className="rating_box_fixing d-flex justify-content-between">
                            <div>
                              <div
                                className="btn card_carsoul_rating d-flex align-items-center"
                                style={{ gap: "10px" }}
                              >
                                <span>
                                  <StarIcon className="card_carsoul_star" />
                                </span>
                                <span>4.8</span>
                              </div>
                            </div>
                            <div className="align-self-center card_carsoul_date">
                              <span>21st Jan 2022</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3 col-sm-3 col-md-3">
                              <img
                                className="land_card_profile"
                                src={CardProfile3}
                              />
                            </div>
                            <div className="col-9 col-sm-9 col-md-9 align-self-center">
                              <span className="land_card_profile_name">
                                Manoj M
                              </span>
                              <br />
                              <span className="land_card_profile_place">
                                Bengaluru
                              </span>
                            </div>
                          </div>
                          <div className="pt-3 card_border_bottom"></div>
                          <div className="pt-3">
                            <span className="land_card_text">
                              I take great pleasure in partnering with Cendrol
                              for their professional goals and ethics. I
                              envisage a strong association with Cendrol in the
                              long run. All the very best, Cendrol!
                            </span>
                          </div>
                          <div className="rating_box_fixing1 d-flex justify-content-between">
                            <div>
                              <div
                                className="btn card_carsoul_rating d-flex align-items-center"
                                style={{ gap: "10px" }}
                              >
                                <span>
                                  <StarIcon className="card_carsoul_star" />
                                </span>
                                <span>4.9</span>
                              </div>
                            </div>
                            <div className="align-self-center card_carsoul_date">
                              <span>5th Dec 2021</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3 col-sm-3 col-md-3">
                              <img
                                className="land_card_profile"
                                src={CardProfile4}
                              />
                            </div>
                            <div className="col-9 col-sm-9 col-md-9 align-self-center">
                              <span className="land_card_profile_name">
                                Sumbul Khan
                              </span>
                              <br />
                              <span className="land_card_profile_place">
                                Bengaluru
                              </span>
                            </div>
                          </div>
                          <div className="pt-3 card_border_bottom"></div>
                          <div className="pt-3">
                            <span className="land_card_text">
                              Cendrol is a good construction company and the
                              <br />
                              reason I'm working with Cendrol is because they
                              <br />
                              are very transparent with the transactions and I
                              get regular updates on client feedback.
                            </span>
                          </div>
                          <div className="pt-5 d-flex justify-content-between">
                            <div>
                              <div
                                className="btn card_carsoul_rating d-flex align-items-center"
                                style={{ gap: "10px" }}
                              >
                                <span>
                                  <StarIcon className="card_carsoul_star" />
                                </span>
                                <span>4.9</span>
                              </div>
                            </div>
                            <div className="align-self-center card_carsoul_date">
                              <span>17th Mar 2022</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3 col-sm-3 col-md-3">
                              <img
                                className="land_card_profile"
                                src={CardProfile5}
                              />
                            </div>
                            <div className="col-9 col-sm-9 col-md-9 align-self-center">
                              <span className="land_card_profile_name">
                                Anurag kumar
                              </span>
                              <br />
                              <span className="land_card_profile_place">
                                Bengaluru
                              </span>
                            </div>
                          </div>
                          <div className="pt-3 card_border_bottom"></div>
                          <div className="pt-3">
                            <span className="land_card_text">
                              Cendrol is pretty professionally organised.The
                              <br />
                              system is well organised and transparent.The{" "}
                              <br />
                              updates regarding the current status of the lead.
                              Looking forward to grow along.
                            </span>
                          </div>
                          <div className="pt-5 d-flex justify-content-between">
                            <div>
                              <div
                                className="btn card_carsoul_rating d-flex align-items-center"
                                style={{ gap: "10px" }}
                              >
                                <span>
                                  <StarIcon className="card_carsoul_star" />
                                </span>
                                <span>4.8</span>
                              </div>
                            </div>
                            <div className="align-self-center card_carsoul_date">
                              <span>25th Mar 2022</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3 col-sm-3 col-md-3">
                              <img
                                className="land_card_profile"
                                src={CardProfile}
                              />
                            </div>
                            <div className="col-9 col-sm-9 col-md-9 align-self-center">
                              <span className="land_card_profile_name">
                                Rakesh
                              </span>
                              <br />
                              <span className="land_card_profile_place">
                                RBD Meadows
                              </span>
                            </div>
                          </div>
                          <div className="pt-3 card_border_bottom"></div>
                          <div className="pt-3">
                            <span className="land_card_text">
                              Value for ownership from Cendrol’s team in
                              pursuing the referral is outstanding. The quality
                              of materials is top-notch at an unbeatable sq. ft.
                              price.
                            </span>
                          </div>
                          <div className="pt-5 d-flex justify-content-between">
                            <div>
                              <div
                                className="btn card_carsoul_rating d-flex"
                                style={{ gap: "10px" }}
                              >
                                <span>
                                  <StarIcon className="card_carsoul_star" />
                                </span>
                                <span>4.9</span>
                              </div>
                            </div>
                            <div className="align-self-center card_carsoul_date">
                              <span>2nd Feb 2022</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3 col-sm-3 col-md-3">
                              <img
                                className="land_card_profile"
                                src={CardProfile1}
                              />
                            </div>
                            <div className="col-9 col-sm-9 col-md-9 align-self-center">
                              <span className="land_card_profile_name">
                                Sathya
                              </span>
                              <br />
                              <span className="land_card_profile_place">
                                New Zealand
                              </span>
                            </div>
                          </div>
                          <div className="pt-3 card_border_bottom"></div>
                          <div className="pt-3">
                            <span className="land_card_text">
                              I take great pleasure in partnering with Cendrol
                              for their professional goals and ethics. I
                              envisage a strong association with Cendrol in the
                              long run. All the very best, Cendrol!
                            </span>
                          </div>
                          <div className="pt-4 d-flex justify-content-between">
                            <div>
                              <div
                                className="btn card_carsoul_rating d-flex"
                                style={{ gap: "10px" }}
                              >
                                <span>
                                  <StarIcon className="card_carsoul_star" />
                                </span>
                                <span>4.9</span>
                              </div>
                            </div>
                            <div className="align-self-center card_carsoul_date">
                              <span>2nd Feb 2022</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* samll screen */}
          <div className="py-5 d-lg-none d-sm-block">
            <div className="pb-4 row">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="card_carsoul_head_txt">
                  <span>
                    Don’t take our word for it, here’s what our partners have to
                    say!
                  </span>
                </div>
              </div>
            </div>
            <div
              id="multi-item"
              className="carousel slide carousel-multi-item"
              data-bs-ride="carousel"
              data-bs-interval="2000"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3 col-sm-3 col-md-3">
                          <img
                            className="land_card_profile"
                            src={CardProfile1}
                          />
                        </div>
                        <div className="col-9 col-sm-9 col-md-9 align-self-center">
                          <span className="land_card_profile_name">
                            Murari Kumar
                          </span>
                          <br />
                          <span className="land_card_profile_place">
                            Bengaluru
                          </span>
                        </div>
                      </div>
                      <div className="pt-3 card_border_bottom"></div>
                      <div className="pt-3">
                        <span className="land_card_text">
                          My client loved their workmanship after visiting their
                          ongoing and completed projects.
                          <br /> It’s a Win-Win!
                        </span>
                      </div>
                      <div className="pt-5 d-flex justify-content-between">
                        <div>
                          <div
                            className="btn card_carsoul_rating d-flex"
                            style={{ gap: "10px" }}
                          >
                            <span>
                              <StarIcon className="card_carsoul_star" />
                            </span>
                            <span>4.9</span>
                          </div>
                        </div>
                        <div className="align-self-center card_carsoul_date">
                          <span>2nd Feb 2022</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3 col-sm-3 col-md-3">
                          <img
                            className="land_card_profile"
                            src={CardProfile2}
                          />
                        </div>
                        <div className="col-9 col-sm-9 col-md-9 align-self-center">
                          <span className="land_card_profile_name">Prince</span>
                          <br />
                          <span className="land_card_profile_place">
                            RBD Meadows
                          </span>
                        </div>
                      </div>
                      <div className="pt-3 card_border_bottom"></div>
                      <div className="pt-3">
                        <span className="land_card_text">
                          Value for ownership from Cendrol’s team in pursuing
                          the referral is outstanding. The quality of materials
                          is top-notch at an unbeatable sq. ft. price.
                        </span>
                      </div>
                      <div className="pt-5 d-flex justify-content-between">
                        <div>
                          <div
                            className="btn card_carsoul_rating d-flex"
                            style={{ gap: "10px" }}
                          >
                            <span>
                              <StarIcon className="card_carsoul_star" />
                            </span>
                            <span>4.8</span>
                          </div>
                        </div>
                        <div className="align-self-center card_carsoul_date">
                          <span>21st Jan 2022</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3 col-sm-3 col-md-3">
                          <img
                            className="land_card_profile"
                            src={CardProfile3}
                          />
                        </div>
                        <div className="col-9 col-sm-9 col-md-9 align-self-center">
                          <span className="land_card_profile_name">
                            Manoj M
                          </span>
                          <br />
                          <span className="land_card_profile_place">
                            Bengaluru
                          </span>
                        </div>
                      </div>
                      <div className="pt-3 card_border_bottom"></div>
                      <div className="pt-3">
                        <span className="land_card_text">
                          I take great pleasure in partnering with Cendrol for
                          their professional goals and ethics. I envisage a
                          strong association with Cendrol in the long run. All
                          the very best, Cendrol!
                        </span>
                      </div>
                      <div className="pt-4 d-flex justify-content-between">
                        <div>
                          <div
                            className="btn card_carsoul_rating d-flex"
                            style={{ gap: "10px" }}
                          >
                            <span>
                              <StarIcon className="card_carsoul_star" />
                            </span>
                            <span>4.9</span>
                          </div>
                        </div>
                        <div className="align-self-center card_carsoul_date">
                          <span>5th Dec 2021</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3 col-sm-3 col-md-3">
                          <img
                            className="land_card_profile"
                            src={CardProfile4}
                          />
                        </div>
                        <div className="col-9 col-sm-9 col-md-9 align-self-center">
                          <span className="land_card_profile_name">
                            Sumbul Khan
                          </span>
                          <br />
                          <span className="land_card_profile_place">
                            Bengaluru
                          </span>
                        </div>
                      </div>
                      <div className="pt-3 card_border_bottom"></div>
                      <div className="pt-3">
                        <span className="land_card_text">
                          Cendrol is a good construction company and the reason
                          I'm working with Cendrol is because they are very
                          transparent with the transactions and I get regular
                          updates on client feedback.
                        </span>
                      </div>
                      <div className="pt-5 d-flex justify-content-between">
                        <div>
                          <div
                            className="btn card_carsoul_rating d-flex"
                            style={{ gap: "10px" }}
                          >
                            <span>
                              <StarIcon className="card_carsoul_star" />
                            </span>
                            <span>4.8</span>
                          </div>
                        </div>
                        <div className="align-self-center card_carsoul_date">
                          <span>17th Mar 2022</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3 col-sm-3 col-md-3">
                          <img
                            className="land_card_profile"
                            src={CardProfile5}
                          />
                        </div>
                        <div className="col-9 col-sm-9 col-md-9 align-self-center">
                          <span className="land_card_profile_name">
                            Anurag kumar
                          </span>
                          <br />
                          <span className="land_card_profile_place">
                            Bengaluru
                          </span>
                        </div>
                      </div>
                      <div className="pt-3 card_border_bottom"></div>
                      <div className="pt-3">
                        <span className="land_card_text">
                          Cendrol is pretty professionally organised.The system
                          is well organised and transparent.The updates
                          regarding the current status of the lead. Looking
                          forward to grow along.
                        </span>
                      </div>
                      <div className="pt-5 d-flex justify-content-between">
                        <div>
                          <div
                            className="btn card_carsoul_rating d-flex"
                            style={{ gap: "10px" }}
                          >
                            <span>
                              <StarIcon className="card_carsoul_star" />
                            </span>
                            <span>4.8</span>
                          </div>
                        </div>
                        <div className="align-self-center card_carsoul_date">
                          <span>25th Mar 2022</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 align-self-center">
              <div
                className="d-flex justify-content-center carousel-button-group"
                style={{ gap: "15px", marginTop: "26px" }}
              >
                <button
                  className="card_carsoul-prev"
                  type="button"
                  data-bs-target="#multi-item"
                  data-bs-slide="prev"
                >
                  <BsArrowLeft className="card_carsoul_icon" />
                </button>
                <button
                  className="card_carsoul-next"
                  type="button"
                  data-bs-target="#multi-item"
                  data-bs-slide="next"
                >
                  <BsArrowRight className="card_carsoul_icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land_Card_Carsoul;
