import React, { useState } from "react";
import ArrowRightAltSharpIcon from "@mui/icons-material/ArrowRightAltSharp";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import NotificationsSharpIcon from "@mui/icons-material/NotificationsSharp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { RiWallet3Fill } from "react-icons/ri";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";
import "./Footer.css";

import useAnalytics from "../CustomHook/useAnalytics";
import { Link } from "react-router-dom";

const Land_footer = () => {
  const { trackEvent } = useAnalytics();
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div
      className="row justify-content-center"
      style={{ background: "#F9F9F9" }}
    >
      <div className="col-11 col-md-11">
        <div className="container-fluid">
          <div className="pb-5">
            <div className="row">
              <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_start">
                <div>
                  <div className="land_footer_text_heads">
                    <span>Start a Conversation</span>
                  </div>
                  <div className="pt-4 land_footer_mail">
                    <a
                      onClick={() =>
                        trackEvent("Clicked On Mail", {
                          button_label: "Clicked On Mail",
                        })
                      }
                      href="mailto:partner@cendrol.com"
                      title="partner@cendrol.com"
                      // className="land_footer_mail"
                    >
                      <span>partner@cendrol.com</span>
                    </a>
                    {/* <span>sales@cendrol.com</span> */}
                    <span>
                      <ArrowRightAltSharpIcon className="land_footer_btn_icon_get" />
                    </span>
                  </div>
                  <br />
                  {/* <Link
                  to={`/privacy`}
                  target="_blank"
                  className="mt-3 PrivacyPolicy "
                >
                  Privacy Policy
                </Link> */}

                  <Link
                    className="mt-3 PrivacyPolicy land_footer_mail"
                    to={`/terms-conditions`}
                  >
                    Terms & Conditions
                  </Link>
                  <br />
                  <Link className="mt-3 PrivacyPolicy land_footer_mail" to={`/terms-services`}>
                    Terms Of Service
                  </Link>
                  <br />
                  <Link className="mt-3 PrivacyPolicy land_footer_mail" to={`/privacypolicy`}>
                    Privacy Policy
                  </Link>
                </div>
              </div>

              <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_center">
                <div>
                  <div className="land_footer_text_heads">
                    <span>General</span>
                  </div>
                  <div className="pt-4 land_footer_number  ">
                    <a
                      onClick={() =>
                        trackEvent("Call Button Clicked", {
                          button_label: "Call Button Clicked",
                        })
                      }
                      href="tel:9632888477"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="+91 9632888477"
                      className="land_footer_number land-foot"
                    >
                      916-427-7477
                    </a>
                  </div>
                  <div className="pt-4 land_footer_icons">
                    <a
                      onClick={() =>
                        trackEvent("twitter", {
                          button_label: "twitter",
                        })
                      }
                      href="https://twitter.com/CendrolPartners"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="twitter"
                      className="land_footer_icons"
                    >
                      <TwitterIcon />
                    </a>

                    <a
                      onClick={() =>
                        trackEvent("linkedin", {
                          button_label: "linkedin",
                        })
                      }
                      href="https://www.linkedin.com/company/cendrol-partners"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="linkedin"
                      className="land_footer_icons"
                    >
                      <LinkedInIcon />
                    </a>

                    <a
                      onClick={() =>
                        trackEvent("Instagram", {
                          button_label: "Instagram",
                        })
                      }
                      href="https://instagram.com/cendrolpartner?igshid=YmMyMTA2M2Y="
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Instagram"
                      className="land_footer_icons"
                    >
                      <InstagramIcon />
                    </a>

                    <a
                      onClick={() =>
                        trackEvent("facebook", {
                          button_label: "facebook",
                        })
                      }
                      href="https://m.facebook.com/cendrolpartners/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="facebook"
                      className="land_footer_icons"
                    >
                      <FacebookSharpIcon />
                    </a>

                    <a
                      onClick={() =>
                        trackEvent("Youtube", {
                          button_label: "Youtube",
                        })
                      }
                      href="https://www.youtube.com/@cendrolpartners"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Youtube"
                      className="land_footer_icons"
                    >
                      <YouTubeIcon />
                    </a>
                    <a
                      onClick={() =>
                        trackEvent("Pinterest", {
                          button_label: "Pinterest",
                        })
                      }
                      href="https://pin.it/5cJyHsR"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Pinterest"
                      className="land_footer_icons"
                    >
                      <PinterestIcon />
                    </a>
                  </div>
                </div>
              </div>
              <div className="pt-5 col-12 col-sm-12 col-md-4 display_footer_end">
                <div>
                  <div className="land_footer_text_heads">
                    <span>Office</span>
                  </div>
                  <div className="pt-3 land_footer_number">
                    <span>
                      Cendrol Construction Contracts PVT LTD <br />
                    </span>
                    <span>
                      3rd floor, Cendrol, 299, 288, Outer Ring Rd, J. P. Nagar,
                      Bengaluru, Karnataka 560078
                    </span>
                  </div>

                  {/* <div className="pt-4 land_footer_privacy_policy">
                  <span>Privacy Policy | Terms & Conditions</span>
                </div> */}
                </div>
              </div>

              {/* <div className="pt-5 col-12 col-sm-12 col-md-3 display_footer_end">
              <div>
                <span onClick={() => toggleTab1(1)}>
                  <a
                    className={
                      toggleState1 === 1
                        ? "land_footer_nav_items land_footer_nav_items_active"
                        : "land_footer_nav_items"
                    }
                    href="#home"
                  >
                    Home
                  </a>
                </span>
                <br />
                <span onClick={() => toggleTab1(2)}>
                  <a
                    className={
                      toggleState1 === 2
                        ? "land_footer_nav_items land_footer_nav_items_active"
                        : "land_footer_nav_items"
                    }
                    href="#benefits"
                  >
                    Benefits
                  </a>
                </span>
                <br />
                <span onClick={() => toggleTab1(3)}>
                  <a
                    className={
                      toggleState1 === 3
                        ? "land_footer_nav_items land_footer_nav_items_active"
                        : "land_footer_nav_items"
                    }
                    href="#faq"
                  >
                    FAQ's
                  </a>
                </span>
                <br />
                <span onClick={() => toggleTab1(4)}>
                  <a
                    className={
                      toggleState1 === 4
                        ? "land_footer_nav_items land_footer_nav_items_active"
                        : "land_footer_nav_items"
                    }
                    href="#features"
                  >
                    Features
                  </a>
                </span>
                <br />
                <span
                  className="land_footer_nav_items"
                  style={{ cursor: "pointer" }}
                  onClick={videoplay}
                >
                 How It Works
                </span>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land_footer;
