import React from "react";
import LogoFrame from "../../Assests/logoFrame.svg";
const Header = () => {
  return (
    <>
      <div
        className="row justify-content-center"
        style={{ borderBottom: "1px solid rgba(14, 14, 14, 0.08)" }}
      >
        <div className="col-11 col-md-11">
          <nav className="navbar navbar-expand-lg navbar-light py-3">
            <div className="container-fluid">
              <a href="https://www.cendrol.com">
                <img
                  src={LogoFrame}
                  className="cendrol_log"
                  alt=""
                  // onClick={{ navigatetocendrol }}
                />
              </a>

              <button
                className="navbar-toggler"
                id="tablet"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon  "></span>
              </button>
              {/*  tablet view start copy code */}

              {/* tablet view end copy code */}
              <div
                className="collapse navbar-collapse "
                id="navbarTogglerDemo02"
              >
                {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0 px-2"> */}

                <div
                  className="d-flex justify-content-around cust_div"
                  style={{ gap: "20px" }}
                >
                  <div class="align-self-center">
                    <button
                      class="btn fontButton"
                      style={{
                        width: "134px",
                        height: "46px",
                        borderRadius: "2px",
                        background:
                          "linear-gradient(142deg, #FFE185 0%, #F6BA00 100%)",
                        color: "#0E0E0E",
                      }}
                      onClick={() => {
                        window.open(
                          "https://play.google.com/store/search?q=cendrol+partner&c=apps&pli=1"
                        );
                      }}
                    >
                      Get the App
                    </button>
                    <button
                      class="btn  m-2 fontButton"
                      style={{
                        width: "140px",
                        height: "46px",
                        borderRadius: "2px",
                        background: "#FFF5D7",
                        border: "0.791px solid var(--black, #0E0E0E)",
                      }}
                      onClick={() => {
                        window.open("https://www.partnerlogin.cendrol.com/");
                      }}
                    >
                      Login / Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* <div class="container mt-2">
                <div class="d-flex justify-content-between">
                    <div className='d-flex align-items-center'>
                        <img src={LogoFrame} alt="Your Image" />
                    </div>
                    <div class="align-self-center">
                        <button class="btn" style={{
                            width: "134px",
                            height: "46px",
                            borderRadius: "2px",
                            background: "linear-gradient(142deg, #FFE185 0%, #F6BA00 100%)",
                            color: "black"
                        }}>Get the App</button>
                        <button class="btn  m-2" style={{
                            width: "140px",
                            height: "46px",
                            borderRadius: "2px", background: "#FFF5D7", border: "0.791px solid var(--black, #0E0E0E)"
                        }} onClick={() => {
                            window.open(
                                "https://www.partnerlogin.cendrol.com/"
                            );
                        }}>Login / Sign Up</button>
                       

                    </div>
                </div>
            </div>
            <hr style={{ background: 'rgba(14, 14, 14, 0.08)', height: "1px" }} /> */}
    </>
  );
};

export default Header;
