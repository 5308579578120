import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import "./styles.css";
import DashBoard from "./Components/DashBoard/DashBoard";
import Landing from "./Components/Landing/Landing";
import Privacy from "./Components/Privacy/Privacy";
import Thankyou from "./Components/Landing/Thankyou";
// firebase
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import TermsConditon from "./Components/Privacy/TermsConditon";
import TermsServices from "./Components/Privacy/TermsServices";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID_KEY,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_KEY,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_KEY,
  appId: process.env.REACT_APP_FIREBASE_APPID_KEY,
  measurementId: process.env.REACT_APP_MEASUREMENTID_KEY,
};

firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <Router basename={"/"}>
      <div className="App">
        <Routes>
          <Route path="*" element={<Navigate to="/index.html" />} />
          <Route path="/" element={<Landing />} />
          <Route path="/dash" element={<DashBoard />} />
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/terms-conditions" element={<TermsConditon/>}/>
          <Route path="/terms-services" element={<TermsServices/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
